@import '../../../assets/sass/styleguide/variables';

.iconHistory {
    width: 42px;
    height: 42px;
    background-color: $gray-50;
    border-radius: 50%;

  }

  .icon {
    font-size: 1.5rem;
    color: $gray-300;
  }

  .emptyList {
    font-size: 12px;
    width: 200px;
    margin: auto;
  }

.employeesListWrapper {
    border-right: 1px solid $gray-100;
    .listHeader {
      padding: 1rem;
      font-weight: bold;
      color: $gray-500;
      border-bottom: 1px solid $gray-100;
      position: sticky;
      top: 0;
      background-color: #ffff;
      z-index: 100;
    }

    .listDiv {
      .list {
        padding: 0.5rem 1rem;
        border-bottom: 1px solid $gray-100;
        cursor: pointer;

        .name {
          font-size: $font-size-14;
          line-height: 18px;
          color: $dark;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }

        .text {
          color: $gray-600;
          font-size: $font-size-11;
          line-height: 14px;
        }
        .company{
            color:$steal-teal;
        }
        &.active {
          background-color: $light;
        }
        &:hover{
          background-color: $light;
        }
      }

      .skeletonList {
        padding: 0;
        padding-right: 1rem;
      }
    }
  }

  .numb{
    font-size: $font-size-base;
    line-height: 16px;
  }

  .profileImage {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 1px solid $gray-100;
    text-align: center;
    color: white;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;

    .img {
        width: 100%;
        height: auto;
        display: block;
    }
  }
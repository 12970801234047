@import "../../assets/sass/styleguide/variables";

.wrapper{
  border: 1px solid $gray-100;
  border-radius: 8px;
  padding: 1rem;
}

.title{
  padding-bottom: 1px;
  font-size: 18px;
}

.gradient {
  background-color: $gray-50;
  background-image: linear-gradient(
    90deg,
    $gray-100 0px,
    rgba($gray-50, 0.9) 300px,
    $gray-100 500px
  );
  background-size: 2000px;
  animation: shine-lines 1.3s infinite linear;
  opacity: 0.8;
}

@keyframes shine-lines {
  0% {
    background-position: -750px - 370px;
  }
  40%,
  100% {
    background-position: 600px - 370px;
  }
}

.title{
  font-weight: bold;
  color: $gray-500;
}

.graphHeight {
	height: calc(100% - 56px);
}
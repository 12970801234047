body.lang-ar {
    // Text align
    @each $breakpoint in map-keys($grid-breakpoints) {
        @include media-breakpoint-up($breakpoint) {
            $infix: breakpoint-infix($breakpoint, $grid-breakpoints);
            .text#{$infix}-start   { text-align: right !important; }
            .text#{$infix}-end  { text-align: left !important; }
        }
    }

        // Margin and Padding
    @each $breakpoint in map-keys($grid-breakpoints) {
        @include media-breakpoint-up($breakpoint) {
            $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

            @each $prop, $abbrev in (margin: m, padding: p) {
                @each $size, $length in $spacers {

                    .#{$abbrev}e#{$infix}-#{$size}{
                        #{$prop}-right: 0 !important;
                        #{$prop}-left: $length !important;
                    }
                    .#{$abbrev}s#{$infix}-#{$size} {
                        #{$prop}-left: 0 !important;
                        #{$prop}-right: $length !important;
                    }
                }
            }

            // Some special margin utils
            .me#{$infix}-auto {
                margin-right: 0 !important;
                margin-left: auto !important;
            }
            .ms#{$infix}-auto {
                margin-left: 0 !important;
                margin-right: auto !important;
            }
        }
    }
}


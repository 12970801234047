@import '../assets/sass/styleguide/variables';

.custom-checkbox {
	position: absolute;
	opacity: 0;
	&:hover + .custom-label:before {
		content: '\ea10';
		color: $gray-500;
	}
	&:checked + .custom-label:before {
		content: '\ea15';
		color: $white;
		background-color: $primary;
		line-height: 20px;
	}
	&:checked + .custom-label:hover {
		&:before {
			background-color: darken($primary, 8%);
		}
	}
	&:disabled + .custom-label {
		color: $gray-300;
		pointer-events: none;
		&:before {
			color: $gray-300;
			background-color: transparent;
		}
		&:hover {
			&:before {
				content: '\ea56';
			}
		}
	}
}

.custom-label {
	position: relative;
	cursor: pointer;
	padding: 0;
	margin: 0;
	color: $steal-teal;
	&:before {
		font-family: 'palmhr';
		color: $gray-300;
		content: '\ea56';
		margin-right: 10px;
		font-size: 1.25rem;
		line-height: 20px;
		width: 20px;
		height: 20px;
		border-radius: 50%;
		position: relative;
		top: 4px;
	}
	&.disabled {
		color: $gray-300;
	}
}

.square-checkbox {
	position: absolute;
	opacity: 0;
	&:hover + .label:before {
		content: '\ea10';
		color: $gray-500;
	}
	&:checked + .label:before {
		content: '\ea15';
		color: $white;
		background-color: $primary;
		line-height: 20px;
	}
	&:checked + .label:hover {
		&:before {
			background-color: darken($primary, 8%);
		}
	}
	&:checked {
		&:disabled + .label {
			pointer-events: none;
			color: $gray-300;
			&:before {
				color: $white;
			}
		}
	}
	&:disabled + .label {
		color: $gray-300;
		&:before {
			color: $gray-300;
		}
		&:hover {
			&:before {
				content: '\ea56';
			}
		}
	}
	&.invalid + .label {
		color: $red;
		&:before {
			color: $red;
		}
		&:hover {
			&:before {
				content: '\ea56';
			}
		}
	}
	&.square:checked + .label {
		&:before {
			content: '\ead4';
			color: $primary;
			background-color: transparent;
		}
	}
	&.square:checked + .label:hover {
		&:before {
			content: '\ead4';
			color: darken($color: $primary, $amount: 10);
			background-color: transparent;
		}
	}
	&.square + .label {
		&:before {
			content: '\ead3';
		}
		&:hover {
			&:before {
				content: '\ead4';
			}
		}
	}
}

.label {
	position: relative;
	cursor: pointer;
	padding: 0;
	margin: 0;
	color: $steal-teal;
	text-transform: none;
	font-size: 13px;
	&:before {
		font-family: 'palmhr';
		color: $gray-300;
		content: '\ea56';
		margin-right: 10px;
		font-size: 1.25rem;
		line-height: 20px;
		width: 20px;
		height: 20px;
		border-radius: 50%;
		position: relative;
		top: 4px;
	}
	&.disabled {
		color: $gray-300;
	}
	&.checked {
		color: $dark;
	}
}

@import "../../assets//sass//styleguide/variables";

.content {
  height: 100vh;
  overflow: hidden;
}
.header {
  border-bottom: 1px solid $gray-100;
}

 .card-wpapper {
  border-radius: 8px;
  box-shadow: 0px 2px 8px 0px rgba(93, 114, 140, 0.1),
    0px 3px 4px -2px rgba(93, 114, 140, 0.08);
}

.details{
  border-radius: 26px;
  border: 1px solid $maximum-teal;
  width: 20%;
  text-align: center;
  font-size: $font-size-14;
  line-height: 20px;
  font-weight: bold;
  color: $secondary;
  padding: 8px 0px;
  margin: 0 8px;
  cursor: pointer;

  &:hover{
    box-shadow: $box-shadow-04dp;
    color: $dark;
  }
}

.backdrop {
  opacity: 0;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: -2;
  left: 0;
  top: 0;
  background-color: rgba(40, 75, 100, 0.4);
  backdrop-filter: blur(2px);
  transition: opacity 0.5s ease-in-out;
  &.active {
    opacity: 1;
    z-index: 1000;
  }
}
.searchDiv {
  padding: 12px 6px;
  border-bottom: 1px solid $gray-100;
  position: sticky;
  background-color: #ffff;
  z-index: 100;
}

// modal
.enter {
  opacity: 0;
  transform: scale(0);
}

.enterActive {
  opacity: 1;
  transform: scale(1);
  transition: opacity 0.7s ease-in-out, transform 0.7s ease-in-out;
  transform-origin: center;
}

.exit {
  opacity: 1;
  transform: scale(1);
}

.exitActive {
  opacity: 0;
  transform: scale(0);
  transition: opacity 0.7s ease-in-out, transform 0.7s ease-in-out;
  transform-origin: center;
}
@import url("https://fonts.googleapis.com/css2?family=Muli:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

html {
  margin: 0;
  padding: 0;
  height: 100%;
  min-height: 100%;
  overflow: hidden;
}

body {
  font-family: "Muli", sans-serif;
  background-color: #e5e9ec;
  overflow: hidden;
  margin: 0;
  padding: 0;
  height: 100%;
  min-height: 100%;
  --primary: #1A938A;
  --primary-rgb: 26, 147, 138;
  --secondary: #5D728C;
  --secondary-rgb: 93, 114, 140;
  --tertiary: #043344;
  --tertiary-rgb: 4, 51, 68;
  --success: #62D26F;
  --success-rgb: 98, 210, 111;
  --info: #4eb2f9;
  --info--rgb: 78, 178, 249;
  --warning: #ffa150;
  --warning-rgb: 255, 161, 80;
  --danger: #f45147;
  --danger-rgb: 244, 81, 71;
  --green: #62D26F;
  --green-rgb: 98, 210, 111;
  --red: #f45147;
  --red-rgb: 244, 81, 71;
  --blue: #4eb2f9;
  --blue-rgb: 78, 178, 249;
  --pink: #FF8BCD;
  --pink-rgb: 255, 139, 205;
  --purple: #D669FE;
  --purple-rgb: 214, 105, 254;
  --indigo: #7A6FF0;
  --indigo-rgb: 122, 111, 240;
  --yellow: #FFC43D;
  --yellow-rgb: 255, 196, 61;
  --orange: #ffa150;
  --orange-rgb: 255, 161, 80;
  --light: #f5f9fa;
  --light-rgb: 245, 249, 250;
  --teal: #16CA9C;
  --teal-rgb: 22, 202, 156;
  --cyan: #11BBD1;
  --cyan-rgb: 17, 187, 209;
  --gray: #93a5b1;
  --gray-rgb: 147, 165, 177;
  --ec-sidebar-background: #25455b;
  --ec-sidebar-background-rgb: 37, 69, 91;
  --holiday: #FF8BCD;
  --holiday-rgb: 255, 139, 205;
  --requests-hr: #11BBD1;
  --requests-hr-rgb: 17, 187, 209;
  --requests-time: #1A938A;
  --requests-time-rgb: 26, 147, 138;
  --requests-pay: #7A6FF0;
  --requests-pay-rgb: 122, 111, 240;
  --requests-pending: #ffa150;
  --requests-pending-rgb: 255, 161, 80;
  --requests-approved: #4eb2f9;
  --requests-approved-rgb: 78, 178, 249;
  --text-main: #043344;
  --text-second: #5D728C;
  --text-third: #93a5b1;
  --text-fourth: #a7b5bf;
  --text-fifth: #bec9d0;
  --text-light: #f5f9fa;
  --dark-blue: #0c3a4e;
}

a {
  text-decoration: none;
}

p {
  margin-bottom: 0;
}

.menu-list a {
  color: $steal-teal;
  text-decoration: none;
  background-color: transparent;
  display: inline-block;
  width: 100%;
  &:hover {
    color: $steal-teal;
  }
}

.main-wrapper {
  position: relative;
  top: -12px;
  overflow: visible;
}

.h-20 {
  height: 20px;
}

.h-100 {
  height: 100%;
}

.mlr-1 {
  margin: 0 1px;
}

.pt1 {
  padding-top: 1px;
}

.border-t {
  border-top: 2px solid $gray-100;
}

.border-l {
  border-left: 1px solid $gray-100;
}

.border-r {
	border-right: 1px solid $gray-100;
}

.border-r {
	border-right: 1px solid $gray-100;
}

.border-btm {
  border-bottom: 1px solid $gray-100;
  &.light {
    border-color: $gray-50;
  }
  &.dark {
    border-color: $gray-dark;
  }
}

.radius-4 {
  border-radius: 4px;
}

.radius-8 {
  border-radius: 8px;
}

.circle {
  border-radius: 50%;
}

.hidden {
  display: none;
}

.sticky {
  position: sticky;
  top: 0;
  z-index: 99;
}

.no-scroll {
  overflow: hidden;
  margin-left: -15px;
}

.cursor-pointer {
  cursor: pointer;
}

.text-steal {
  color: $steal-teal !important;
}

.text-light {
  color: $light !important;
}

.text-orange {
  color: $orange !important;
}

.text-yellow {
  color: $yellow !important;
}

.text-gray {
  color: $gray-500 !important;
}

.text-teal {
  color: $teal !important;
}

.text-blue {
  color: $blue !important;
}

.text-pink {
  color: $pink !important;
}

.text-purple {
  color: $purple !important;
}

.text-indigo {
  color: $indigo !important;
}

.gray-300 {
  color: $gray-300 !important;
}

.gray-400 {
  color: $gray-400 !important;
}

.bg-steal {
  background-color: $steal-teal;
}

.bg-teal {
  background-color: $teal;
}

.bg-orange {
  background-color: $light-orange;
}

.bg-light {
  background-color: $light;
}

ul {
  list-style-type: none;
  padding: 0;
}

.form-control {
  background-color: transparent;
  &:focus {
      border-color: var(--primary);
      background-color: transparent;
  }
}


.form-control:disabled {
  border: 1px solid $gray-100;
  background-color: $gray-50;
}

.input-wrapper {
  position: relative;
  padding: 0 1rem;
  height: 82px;
  .spinner-border {
    position: absolute;
    top: 38%;
    right: 8px;
  }
}

.scroll-bar {
  > div:nth-child(2) {
    visibility: hidden;
  }
}

input,
textarea {
  &:hover {
    border: 1px solid $gray-200;
    box-shadow: 0 2px 2px 2px rgba(93, 114, 140, 0.06);
  }
  &:focus {
    outline: none;
    border: 1px solid $primary;
    box-shadow: 0 2px 3px 2px rgba(50, 198, 176, 0.16);
  }
}

.react-select__input {
  input:focus {
    outline: none;
    border: none;
    box-shadow: none;
  }
}

.navbar-nav {
  .active .nav-link{
    color: $white;
  }
}

.was-validated .form-control:invalid,
.form-control.is-invalid {
  border-color: $red !important;
  box-shadow: 0 2px 3px 2px rgba(204, 68, 74, 0.16);
  background-image: none;
  padding-right: 0.25rem;
}
.was-validated .form-control:valid {
  background-image: none;
  padding-right: 0.25rem;
  &:focus {
    box-shadow: 0 2px 3px 2px rgba(135, 189, 181, 0.16);
  }
}
.invalid-feedback {
  font-size: 0.68rem;
}
.form-control:not(.form-control-lg):not(.form-control-sm) {
  font-size: 1rem;
}
.form-control[type="checkbox"],
.form-control[type="select"] {
  box-shadow: none;
  border-color: transparent;
}

.text-teal {
  color: $teal;
}

.form-control[readonly],
.form-control-plaintext {
  background-color: $white;
  border: none;
  box-shadow: none;
  &:focus,
  &:hover {
    border: none;
    box-shadow: none;
  }
}

.tooltiptext {
  visibility: hidden;
  font-size: 0.68rem;
  height: 20px;
  width: 70px;
  background-color: $white;
  border-radius: 2px;
  position: absolute;
  color: $dark;
  z-index: 1;
  bottom: 140%;
  left: -11px;
  opacity: 0;
  transition: opacity 0.3s;
  padding-top: 2px;
}

.tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -3px;
  border-width: 3px;
  border-style: solid;
  border-color: $white transparent transparent transparent;
}

.tooltip-box {
  position: relative;
  &:hover {
    cursor: pointer;
    .tooltiptext {
      visibility: visible;
      opacity: 1;
    }
    .tooltip-content {
      opacity: 1;
      transform: scale(1);
    }
  }
}

.btn:active, .btn:first-child:active, .btn.active, .btn.show {
  border-color: transparent !important;
}


.btn-outline-dark {
  border: 1px solid $gray-200;
}

.btn-primary:disabled {
  opacity: 0.5;
}

.react-select__menu {
  .css-wqgs6e {
    background-color: rgba(50, 198, 176, 0.2);
  }
}

.styled-select {
  .react-select__control,
  .react-select-creatable__control {
    border: 1px solid $gray-100;
    box-shadow: none;
    &:hover {
      border-color: $gray-200;
      box-shadow: 0 2px 2px 2px rgba(93, 114, 140, 0.06);
    }
    &:focus {
      border-color: $light-primary;
      box-shadow: 0 2px 3px 2px rgba(50, 198, 176, 0.16);
    }
  }

  .react-select__control.react-select__control--is-focused,
  .react-select-creatable__control.react-select-creatable__control--is-focused {
    border-color: $light-primary;
    box-shadow: 0 2px 3px 2px rgba(50, 198, 176, 0.16);
  }

  .react-select__option.react-select__option-is-selected,
  .react-select-creatable__option.react-select-creatable__option-is-selected {
    background-color: $primary;
    color: $dark;
  }

  .react-select__option.react-select__option--is-focused.react-select__option--is-selected {
    background-color: $primary;
  }

  .react-select__option.react-select__option--is-selected {
    background-color: $primary;
  }

  .react-select__option.react-select__option--is-focused {
    background-color: lighten($primary, 30%);
  }
  .css-16pqwjk-indicatorContainer,
  .css-1thkkgx-indicatorContainer {
    padding: 0 6px;
  }
}

#react-select-2-input {
  &:focus {
    border: none;
  }
}

.react-select__indicator-separator {
  opacity: 0;
}

.error {
  font-size: 0.68rem;
  color: $danger;
  height: 20px;
}

:global .simplebar-scrollbar:before {
  background-color: $gray-300 !important;
}

.panel {
  background-color: $white;
  border-radius: 4px;
  box-shadow: $box-shadow-04dp;
  padding: 1.5rem 2rem 2.5rem;
  margin-bottom: 1rem;
  overflow: hidden;
}

.row-small {
  margin-right: -12px;
  margin-left: -12px;
}

.col-8a {
  position: relative;
  width: 100%;
  padding-right: 16px;
  padding-left: 16px;
  flex: 0 0 72%;
  max-width: 72%;
}

.col-4a {
  position: relative;
  width: 100%;
  padding-right: 16px;
  padding-left: 16px;
  flex: 0 0 28%;
  max-width: 28%;
}

.chart-legend li span {
  display: inline-block;
  width: 12px;
  height: 12px;
  margin-right: 5px;
}

.bullet {
  &:before {
    content: "\2022";
    font-size: $font-size-20;
    color: $primary;
    margin-right: 0.75rem;
    line-height: 0.75rem;
  }
}

// STEFAN SCSS
.height-100vh {
  height: 100vh;
}

.error-red {
  color: $red !important;

  &::before,
  &::after {
    color: $red !important;
  }
}

.success-green {
  color: $green !important;

  &::before,
  &::after {
    color: $green !important;
  }
}

.phr-from-group.form-group {
  .input-group {
    .input-group-prepend {
      position: absolute;
      top: 10px;
      left: 2px;
      height: calc(100% - 4px);
      z-index: 9;
      width: 36px;

      .input-group-text {
        background-color: transparent;
        border: 0;
        border-radius: 24px;
        color: $primary;
        width: 100%;
      }
    }

    .form-control {
      padding-left: 36px;
      border-radius: 24px;
      border: 1px solid rgb(233, 237, 239);
    }
  }
}

.input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  border-radius: 26px !important;
}

.custom-control-label {
  &::before {
    background-color: transparent;
  }
}

.range-slider__wrap {
  width: 195px !important;
}

.ReactModal__Overlay {
  opacity: 0;
  transition: all 500ms ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

.is-invalid {
  .react-select__control,
  .react-select-creatable__control {
    border-color: $danger !important;
  }
}

.react-calendar {
  min-width: 270px !important;
  min-height: 281px !important;
}

.react-date-picker,
.react-calendar {
  width: 100% !important;
  &.form-control {
    padding: 0 !important;
  }
  button {
    &:hover,
    &:focus {
      border: 0 !important;
      box-shadow: 0 !important;
      outline: 0 !important;
    }
  }
  .react-calendar {
    min-height: 256px !important;
  }
}

.react-date-picker__inputGroup {
  padding-left: 0.5rem !important;
  select {
    color: $dark !important;
    -moz-appearance: none !important;
    -webkit-appearance: none !important;
    pointer-events: none !important;
    &:focus {
      box-shadow: 0 !important;
      outline: 0 !important;
    }
  }
  input {
    color: $dark !important;
  }
}

.form-control-sm {
  .react-date-picker__inputGroup {
    position: relative !important;
    height: 30px !important;
    top: -3px !important;
  }
}

.react-date-picker__inputGroup__input {
  font-size: 16px !important;
}

.react-date-picker__inputGroup__input:invalid {
  background: none !important;
}

.react-date-picker__wrapper {
  width: 100% !important;
  border: 0 !important;
  height: 34px;
  &:hover,
  &:focus {
    border: 0 !important;
    box-shadow: 0 !important;
    outline: 0 !important;
  }
  button {
    display: flex !important;
    &:focus {
      border: 0 !important;
      box-shadow: 0 !important;
      outline: 0 !important;
    }
    &:hover {
      color: $gray-900 !important;
    }
  }

  .react-date-picker__button {
    align-items: center;
  }

  .icon-calendar-dates {
    color: $gray-500 !important;
    font-size: 1.25rem !important;
  }

  .icon-cross-x {
    font-size: 0.875rem !important;
    color: $gray-300 !important;
  }

  input {
    &:hover,
    &:focus {
      border: none !important;
      box-shadow: none !important;
      outline: none !important;
    }
  }
}

.react-date-picker {
  height: 35px !important;
  &.react-date-picker--disabled {
    opacity: 0.6;
  }
}

.react-date-picker__calendar {
  width: 270px !important;
  z-index: 100 !important;
  .react-calendar {
    border: 0 !important;
    border-radius: 4px !important;
    box-shadow: $box-shadow-06dp !important;
  }
}

.btm-box {
  .react-date-picker__calendar--open {
    top: 100% !important;
    bottom: unset !important;
  }
}

.react-calendar {
  border: 0 !important;
}

abbr {
  font-size: 0.75rem !important;
}

.react-calendar__month-view__weekdays {
  border-bottom: 1px solid $gray-100 !important;
  abbr {
    font-size: 0.68rem !important;
    color: $gray-300 !important;
    font-weight: normal !important;
    text-decoration: none !important;
  }
}

.react-calendar__navigation {
  padding-top: 0.25rem !important;
  button:disabled {
    pointer-events: none !important;
    background-color: transparent !important;
  }
  button:enabled:hover,
  button:enabled:focus {
    background-color: transparent !important;
  }
  .react-calendar__navigation__prev2-button,
  .react-calendar__navigation__next2-button {
    display: none !important;
  }
  .react-calendar__navigation__prev-button,
  .react-calendar__navigation__next-button {
    position: relative !important;
    color: transparent !important;
    &:after {
      content: "\ea3c" !important;
      font-family: "palmhr" !important;
      display: inline-block !important;
      position: absolute !important;
      color: $gray-200 !important;
      width: 32px !important;
      height: 40px !important;
      font-size: 1rem !important;
      right: 4px !important;
    }
    &:hover:after {
      color: $gray-500 !important;
    }
  }
  .react-calendar__navigation__prev-button {
    &:after {
      content: "\ea3d" !important;
      right: -4px !important;
    }
  }
  .react-calendar__navigation__label {
    font-size: 0.875rem !important;
    color: $dark !important;
    &:hover {
      color: $primary !important;
    }
    &:disabled {
      color: $gray-200 !important;
    }
  }
}

.react-calendar__navigation {
  margin-bottom: 0.25rem !important;
  button {
    min-width: 32px !important;
  }
}

.react-calendar__navigation__arrow {
  color: $gray-500 !important;
}

.react-calendar__month-view,
.react-calendar__year-view__months,
.react-calendar__decade-view {
  padding: 0 0.5rem 0.812rem !important;
}

.react-calendar__month-view__days {
  padding-top: 0.5rem !important;
}

.react-calendar__month-view__days__day,
.react-calendar__month-view__days__day--weekend {
  color: $dark !important;
}

.react-calendar__month-view__days__day--neighboringMonth {
  color: $gray-500 !important;
}

.react-calendar__tile {
  border-radius: 14px !important;
  padding: 0.15rem 0.25rem !important;
  margin: 0.2rem 0 !important;
  &.react-calendar__tile--active,
  &.react-calendar__tile--hasActive {
    background-color: $primary !important;
    color: $white !important;
    &:hover {
      background-color: darken($primary, 10%) !important;
    }
  }
  &:disabled {
    background-color: transparent !important;
    color: $gray-200 !important;
  }
}

.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background-color: $gray-100 !important;
  &.react-calendar__tile--active,
  &.react-calendar__tile--hasActive {
    background-color: darken($primary, 10%) !important;
  }
}

.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile {
  padding: 0.5rem 0.5rem !important;
  border-radius: 16px !important;
  color: $dark !important;
  &.react-calendar__tile--active,
  &.react-calendar__tile--hasActive {
    background-color: $primary !important;
    color: $white !important;
    &:hover {
      background-color: darken($primary, 10%) !important;
    }
  }
  &:disabled {
    color: $gray-200 !important;
  }
}

body[class*="lang-ar"] {
  .react-calendar__navigation__arrow {
    transform: rotate(180deg) !important;
  }
  .react-date-picker__inputGroup {
    padding-right: 8px;
  }
}

.react-date-picker.is-warning {
  .react-date-picker__wrapper {
    .react-date-picker__inputGroup {
      .react-date-picker__inputGroup__input {
        color: $orange !important;
      }
    }
  }
}

* {
  outline: none;
}

.table td {
  cursor: pointer;
}

.table td,
.table th {
  padding: 0.75rem 1rem !important;
  border-bottom: 1px solid #e9edef;
  vertical-align: middle !important;
  line-height: 32px;
}

 tr:hover {
  background-color: rgba(0, 0, 0, 0.03);
}

.table thead th {
  border-bottom: 1px solid #e9edef !important;
  border-top: none !important;
  padding: 0.75rem 1rem;
  font-weight: 600;
  color: #5d728c;
  &:first-child {
    border-radius: 4px 0 0 0;
  }
  &:last-child {
    border-radius: 0 4px 0 0;
  }
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.02) !important;
  &:hover {
    background-color: rgba(0, 0, 0, 0.03) !important;
  }
}

.table-striped tbody tr:nth-of-type(even) {
  &:hover {
    background-color: rgba(0, 0, 0, 0.03) !important;
  }
}

.page-link {
  padding: 4px 8px !important;
  border-color: #93a5b1 !important;
  color: #5d728c !important;
}

.page-item,
.page-item.active .page-link {
  background-color: transparent !important;
  border-color: #d4dbe0 !important;
  color: #5d728c !important;
  font-size: 0.875rem;
}

.custom-control-input:checked ~ .custom-control-label::before {
  background-color: rgb(50, 198, 176) !important;
  border-color: rgb(50, 198, 176) !important;
}

.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: none !important;
}

.form-control.newClientInputs {
  height: 2.2rem !important;
  padding: 0.3rem 0.5rem !important;
}

.form-control:focus {
  box-shadow: none !important;
  border-color: #d4dbe0 !important;
}

.page-link:focus {
  box-shadow: none !important;
}

.label-title {
  font-size: 11px;
  text-transform: uppercase;
  color: #93a5b1;
  font-weight: bold;
}

.info-text {
  font-size: 12px;
  color: #93a5b1;
  line-height: 14px;
  font-style: italic;
}

.btn-secondary:focus,
.btn-secondary.focus {
  box-shadow: none !important;
}

.modal-footer {
  border: none !important;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.icon-info {
  font-size: 12px;
  color: #93a5b1;
}

//---- page container/wrapper style -------
.wrapper {
  min-height: 100vh;
  .header {
    border-bottom: 1px solid #e9edef;
    h2 {
      line-height: 30px;
      font-weight: bold;
      font-size: 20px;
    }
  }
}

.tab-subtitle {
  line-height: 14px;
  font-weight: normal;
  font-size: 14px;
  font-style: italic;
  color: #93a5b1;
}

@import "assets/sass/styleguide/variables";

.emptyList {
  width: 400px;

  .iconWrapper {
    width: 146px;

    img {
      width: 100%;
    }

    i {
      font-size: 146px;
    }
  }

  .title {
    font-size: $font-size-20;
    line-height: 24px;
    color: var(--secondary);
  }

  .lead {
    font-size: $font-size-12;
    line-height: 18px;
    color: var(--text-fifth);
  }
}
@import '../assets/sass/styleguide/variables';


.inputWrapper {
  min-height: 80px;
}

.addNewButton {
  font-size: $font-size-base;
  color: var(--green);
  cursor: pointer;
}

.addTooltip {
  left: -134% !important;
}

.tooltip {
  color: $gray-500;
}

.selectFieldSheet {
  > div > div {
    border: 0 !important;
  }

  &.showWarning {
    > div > div {
      border: 1px solid $orange !important;
      border-radius: 0;
    }
  }
}
@import '../../../../assets/sass/styleguide/variables';
.emptyList {
    width: 230px;
}

.iconHistory {
    width: 88px;
    height: 88px;
    background-color: $gray-50;
    border-radius: 50%;
}

.icon {
    font-size: 2.5rem;
    color: $gray-300;
}

.invoiceBtn {
    display: flex;
    justify-content: end;
    align-items: center;
    color: $cyan;
    font-size: $font-size-14;
    padding: 0.25rem 0;
    margin: 0.5rem 0;
    cursor: pointer;
    font-weight: bold;
    &:hover {
        color: darken($color: $cyan, $amount: 10%);
    }
}
@import '../assets/sass/styleguide/variables';

.wrapper {
  cursor: pointer;
  border-radius: 8px;
  transition: box-shadow 0.1s ease-in-out;
  border: 1px solid rgb(233, 237, 239);
  height: 100%;
  &:hover {
    border: none;
    box-shadow: 0px 8px 20px 6px rgba(93, 114, 140, 0.1);
  }
}

.iconBox {
  height: 64px;
  width: 64px;
  border-radius: 50%;
  i {
    font-size: $font-size-28;
  }
}

.description {
  font-size: $font-size-13;
  color: $gray-500;
}

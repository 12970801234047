@import "../assets/sass/styleguide/variables";

.tooltip {
  position: absolute;
  left: 100%;
  top: 50%;
  z-index: 9999;
  display: inline-block;
  height: 22px;
  line-height: 22px;
  margin-top: -13px;
  margin-left: 12px;
  background: $white;
  color: $dark;
  padding: 0 0.5rem;
  border-radius: 4px;
  font-size: 0.68rem;
  white-space: nowrap;
  opacity: 0;
  transform: scale(0.7);
  transform-origin: left center;
  pointer-events: none;
  font-weight: normal;
  text-transform: initial;
  transition: opacity 0.2s ease, transform 0.2s ease;
  box-shadow: $box-shadow-04dp;
  &:before {
    content: "";
    position: absolute;
    top: 50%;
    left: -3px;
    margin-top: -3px;
    display: inline-block;
    border-top: 3px solid transparent;
    border-right: 3px solid $white;
    border-bottom: 3px solid transparent;
  }
  &.right {
    left: initial;
    right: 100%;
    transform-origin: right;
    &:before {
      top: 50%;
      left: initial;
      right: -3px;
      margin-top: -3px;
      border-top: 3px solid transparent;
      border-left: 3px solid $white;
      border-bottom: 3px solid transparent;
      border-right: none;
    }
  }
  &.top {
    left: -50%;
    top: initial;
    bottom: 100%;
    transform-origin: top center;
    margin: 0 0 8px;
    &:before {
      top: 105%;
      left: initial;
      right: 50%;
      transform: rotate(90deg);
      margin-top: -3px;
      border-top: 3px solid transparent;
      border-left: 3px solid $white;
      border-bottom: 3px solid transparent;
      border-right: none;
    }
  }
  &.left {
    left: initial;
    right: 100%;
    transform-origin: right;
    margin-left: auto;
    margin-right: 12px;
    &:before {
      top: 50%;
      right: -3px;
      left: initial;
      margin-top: -3px;
      border-top: 3px solid transparent;
      border-left: 3px solid $white;
      border-bottom: 3px solid transparent;
      border-right: none;
    }
  }
}


body[class*="lang-ar"] {
  .tooltip {
    left: initial;
    right: 100%;
    margin-left: auto;
    margin-right: 12px;

    &:before {
      left: initial;
      right: -3px;
      border-left: 3px solid $white;
      border-right: none;
    }
    &.right {
      left: 100%;
      right: initial;
      &:before {
        left: -3px;
        right: initial;
        border-left: none;
        border-right: 3px solid $white;
      }
    }
    &.top {
      left: initial;
      right: -50%;
      &:before {
        left: 50%;
        right: initial;
        border-left: none;
        border-right: 3px solid $white;
      }
    }
    &.left {
      left: 100%;
      right: initial;
      margin-left: 12px;
      margin-right: auto;
      &:before {
        right: initial;
        left: -3px;
        border-left: none;
        border-right: 3px solid $white;
      }
    }
  }
}

@import 'assets/sass/styleguide/variables';
.filterMenu {
    top: 40px;
    position: absolute;
    box-shadow: $box-shadow-04dp;
    z-index: 10;
    width: 180px;
    max-height: 150px;
    overflow:scroll;
    font-size: $font-size-14;
    .listItem:hover {
        background-color: $gray-50;
        i {
            color: $primary;
        }
    }
    .link {
        color: $dark;
        cursor: pointer;
        word-wrap: normal;
    }
    i {
        font-size: $font-size-base;
        color: $gray-500;
    }
}

body[class*='lang-ar'] {
    .filterMenu {
        right: inherit;
        left: 12px;
    }
}
@import '../../assets/sass/styleguide/variables';

.header {
	width: 100%;
	height: 240px;

	.closeButton {
		position: absolute;
		top: 1rem;
		right: 1rem;
	}

	img {
		width: 100%;
		max-height: 240px;
		position: relative;
		top: -1px;
	}
}

.subHeader {
	padding: 0 32px 2rem;
	border-bottom: 1px solid $gray-50;

	.title {
		i {
			font-size: 2rem;
			color: $orange;
			margin-right: 8px;
		}

		span {
			font-size: 24px;
			line-height: 32px;
			font-weight: bold;
			color: $dark;
		}
	}

	.navigation {
		max-width: 152px;
		margin: auto;
	}

	.releaseDate {
		font-size: 12;
		line-height: 16px;
		text-align: right;

		div {
			color: $dark;
			margin-bottom: 8px;
		}

		p {
			color: $gray-500;
			margin: 0;
		}
	}
}

.sectionTitle {
	font-size: 20px;
	font-weight: bold;
	color: $dark;
	margin-bottom: 1rem;
}

.ul {
	li {
		padding-left: 1.5rem;
		position: relative;
		font-size: 14px;
		color: $steal-teal;
		margin-bottom: 1rem;

		&::before {
			font-family: 'palmhr';
			content: '\ea55';
			font-size: 1.25rem;
			line-height: 20px;
			color: $primary;
			position: absolute;
			height: 8px;
			width: 8px;
			z-index: 1;
			left: -4px;
			top: 0;
		}

		&.noInfo {
			font-weight: bold;
			padding-left: 0;

			&::before {
				display: none;
			}
		}
	}
}

.infoSection {
	padding-left: 48px;
	margin-bottom: 2rem;
	position: relative;

	.icon {
		position: absolute;
		top: -8px;
		left: 0;
		width: 32px;
		height: 32px;
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: 16px;
		border: 1px solid $gray-100;
		border-radius: 32px;
		color: $gray-500;
	}
}

.emptyList {
	width: 164px;
}

.iconHistory {
	width: 64px;
	height: 64px;
	background-color: $gray-50;
	border-radius: 50%;
}

.icon {
	font-size: 2rem;
	color: $gray-300;
}

.arStyle {
	* {
		direction: rtl;
	}

	h5 {
		text-align: right;
	}

	.ul {
		li {
			padding-right: 1.5rem;
			padding-left: 0;
			text-align: right;

			&::before {
				right: auto;
				right: -4px;
			}

			&.noInfo {
				padding-right: 0;
			}
		}
	}

	.arScrollbar {
		> div:first-of-type {
			margin-right: auto !important;
			margin-left: -15px !important;
		}

		> div:nth-child(2) {
			visibility: hidden;
		}

		> div:last-of-type {
			right: auto !important;
			left: 2px;
		}
	}

	.publishBtn {
		margin-left: 0 !important;
		margin-right: 2rem !important;
	}

	.subHeader {
		.title {
			text-align: right;
		}

		.releaseDate {
			text-align: left;
		}
	}

	.infoSection {
		padding-left: 0;
		padding-right: 48px;
		i {
			left: auto;
			right: 0;
		}
	}
}

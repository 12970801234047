.login-div {
  height: 100vh;
  background: rgb(245, 249, 250);
  .logo-div {
    position: absolute;
    top: 16px;
    left: 16px;
    width: 100%;
    max-width: 64px;

    img {
      width: 100%;
    }
  }
  .login-form {
    width: 100%;
    .form-logo-div {
      position: relative;
      background: $maximum-teal;
      border-radius: 144px;
      box-shadow: inset 2px 2px 16px 8px rgba(163, 177, 198, 0.04),
        12px 12px 16px 0px rgba(163, 177, 198, 0.24),
        -12px -12px 16px 3px rgba(255, 255, 255, 0.59);
      height: 120px;
      width: 120px;
      display: flex;
      align-content: center;
      align-items: center;
      justify-content: center;
      margin: auto;

      .logo-icon {
        width: 100%;
        max-width: 80px;
      }
      .crown-img {
        width: 47px;
        height: auto;
        position: absolute;
        top: -15px;
        right: 7px;
        transform: rotate(27deg);
      }
    }
    form {
      max-width: 400px;
      margin: auto;
      .phr-email-icon {
        &::before {
          font-family: "palmhr";
          content: "\ea84";
          font-size: 1.25rem;
          line-height: 20px;
          color: $primary;
          position: absolute;
          height: 20px;
          width: 20px;
          z-index: 1;
        }
      }
      .phr-password-icon {
        &::before {
          font-family: "palmhr";
          content: "\e98f";
          font-size: 1.25rem;
          line-height: 20px;
          color: $primary;
          position: absolute;
          height: 20px;
          width: 20px;
          z-index: 1;
        }
      }
      .text {
        font-size: 0.75rem;
        color: $gray-500;
      }
      .custom-control-label {
        font-size: 13px;
        color: $steal-teal;
        line-height: 24px;
      }
      button {
        border-radius: 50px;
        padding: 12px 0;
        background-color: $maximum-teal;

        &.btn-primary {
          border-color: $maximum-teal;
        }
      }
      .loadingButton {
        border-radius: 50px;
        padding: 12px 0;
        width: 100%;
        font-size: $font-size-14;
        line-height: 1.6;
      }
    }
  }
  .lead {
    color: $gray-500;
    font-size: 1rem;
  }
  .login-footer {
    display: flex;
    align-self: flex-end;
    position: absolute;
    a {
      cursor: pointer;
      color: $gray-500;
      padding: 0 0.8rem;
      border-right: solid 1px $gray-500;
      &:hover {
        text-decoration: none;
      }
      &:last-child {
        border-right: 0;
      }
    }
  }
  .login-img-div {
    background: url("../../img/login-bg.png");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 100vh;
  }
}

@import "../../assets/sass/styleguide/variables";
.wrapper {
    cursor: pointer;
    border-bottom: 1px solid $gray-50;
    &:hover {
        background-color: $light;
        .deleteBtn {
            visibility: visible;
        }
    }
    &:first-of-type {
        border-top: 8px solid $white;
    }
}

.mail {
    font-size: $font-size-11;
    color: $gray-500;
}

.values {
    min-width: 140px;
    font-size: $font-size-14;
    color: $steal-teal;
}

.icon {
    text-align: center;
}

.status {
    display: flex;
    justify-content: center;
    text-align: center;
    border-radius: 4px;
    width: 70%;
    padding: 0.25rem;
    font-size: $font-size-12;
    &.received {
        border: 1px solid $blue;
        color: $blue;
        background-color: rgba($color: $blue, $alpha: 0.1);
    }
    &.processed {
        border: 1px solid $orange;
        color: $orange;
        background-color: rgba($color: $orange, $alpha: 0.1);
    }
    &.hired {
        border: 1px solid $green;
        color: $green;
        background-color: rgba($color: $green, $alpha: 0.1);
    }
    &.rejected {
        border: 1px solid $red;
        color: $red;
        background-color: rgba($color: $red, $alpha: 0.1);
    }
}
@import 'assets/sass/styleguide/variables';
@import 'assets/sass/styleguide/mixins';

.inputField {
  position: absolute;
  opacity: 0;
  cursor: pointer;

  &:checked + .label {
    color: var(--text-main);
    &:before {
      content: "\ea16";
      color: var(--primary);
    }
  }

  &:disabled + .label {
    opacity: 0.5;
    pointer-events: none;
    color: var(--text-second);
  }

  &:disabled:checked + .label {
    color: var(--text-main);
  }

  &.disableAutoScroll {
    display: none;
  }
  
  &.invalid + .label {
    color: var(--danger);
    &:before {
      color: var(--danger);
    }
  }

  &:checked + .label.likeRadio:before {
    content: "\ea54";
  }

  &:checked + .label.indeterminate:before {
    content: "\ea1a";
  }

  &.square:checked + .label {
    &:before {
      border-radius: 8px;
      content: "\ead5";
    }
  }

  &.square:checked + .label.indeterminate:before {
    content: "\eadc";
  }

  &.square + .label {
    &:before {
      border-radius: 8px;
      content: "\ead3";
    }
  }

  &.green {
    &:checked + .label {
      &:before {
        color: var(--green);
      }
    }
  }
}

.label {
  @include bg-states(before);
  cursor: pointer;
  padding: 0;
  margin: 0;
  color: var(--text-third);
  line-height: 24px;
  &:before {
    font-family: 'palmhr';
    color: var(--text-third);
    content: "\ea56";
    display: inline-block;
    vertical-align: text-top;
    font-size: 1.25rem;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    text-align: center;
    line-height: 24px;
  }
}

.subLabel {
  font-size: $font-size-13;
  line-height: 16px;
  color: var(--text-third);
}
@import "assets/sass/styleguide/variables";

.rowWrapperContract {
  transition: all ease 0.4s;
  position: relative;
  border-bottom: 1px solid $gray-100;
  cursor: pointer;

  &:hover {
    background-color: $light;

    .deleteBtn {
      display: block;
    }
  }

  &.noHover {
    cursor: default;

    &:hover {
      background-color: transparent;
    }
  }
}

.deleteBtn {
  position: absolute;
  top: 16px;
  right: 16px;
  color: var(--text-third);
  display: none;
  z-index: 9999;
}

.expiredContract {
  opacity: 0.6;
}

.iconWrapper {
  background-color: rgba($color: var(--secondary-rgb), $alpha: 0.1);
  padding: 1rem;
  border-radius: 50px;

  &.active {
    background-color: rgba($color: var(--blue-rgb), $alpha: 0.1);
  }

  &.activePayment {
    background-color: rgba($color: var(--indigo-rgb), $alpha: 0.1);
  }

  &.activeContract {
    background-color: rgba($color: var(--green-rgb), $alpha: 0.1);
  }
}

.iconSalary {
  font-size: 24px;
  color: var(--text-third);

  &.active {
    color: var(--blue);
  }

  &.activePayment {
    color: var(--indigo);
  }

  &.activeContract {
    color: var(--green);
  }
}

.contractName {
  color: var(--text-main);
  font-size: $font-size-base;
  line-height: 24px;
  margin: 0 0 4px;
  font-weight: bold;
}

.contractDate {
  color: var(--text-second);
  font-size: $font-size-14;
  line-height: 20px;
}

.additionalInfoText {
  font-size: $font-size-13;
  color: var(--text-third);
  opacity: 0.85;
  line-height: 1rem;
}

.infoWrapper {
  font-size: $font-size-14;
  width: 100%;
  line-height: 20px;

  .info {
    .icon {
      font-size: $font-size-base;
      line-height: 20px;
    }

    .type {
      color: var(--text-third);
      font-size: $font-size-11;
      line-height: 1.5;
      text-transform: uppercase;
    }

    .name {
      color: var(--text-main);
      font-size: $font-size-14;
      line-height: 1.5;
    }

    .viewAll{
      color: var(--primary);
      font-size: 14px;
    }
  }

  .amount {
    text-align: right;
    font-weight: bold;
    color: var(--text-main);
  }


}


body[class*="lang-ar"] {
  .deleteBtn {
    right: initial;
    left: 16px;
  }
}

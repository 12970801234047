@import "assets/sass/styleguide/variables";


.wrapper {
    min-height:100vh;
}

.emptyList {
	width: 164px;
}

.iconHistory {
	width: 64px;
	height: 64px;
	background-color: $gray-50;
	border-radius: 50%;
}

.icon {
	font-size: 2rem;
	color: $gray-300;
}
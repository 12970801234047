@import 'assets/sass/styleguide/variables';
.row {
    cursor: pointer;
    &:hover {
        background-color:transparent;
        border-color: transparent;
    }
}
.data {
    padding: 1rem 0.75rem 1rem 0;
    color: $steal-teal;
    font-size: $font-size-14;
    vertical-align: middle;
    &:last-child {
        padding-right: 1rem;
    }
  }
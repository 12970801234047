@import "assets/sass/styleguide/variables";
.sendingOverlay {
    position: absolute;
    z-index: 100;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.95);
}

.box {
    position: absolute;
    height: 110px;
    width: 110px;
    left: 50%;
    transform: translate(-50%, 0);
}

.content {
    width: 448px;
}

.text {
    font-size: $font-size-base;
    color: var(--text-second);
}

.title {
    font-size: $font-size-24;
}

.image {
    width: 124px;
    height: auto;
}
@import "assets/sass/styleguide/variables";

.basicHeader {
  background-color: rgba($color: $blue, $alpha: 0.1);
  border-radius: 4px;
  p {
    font-size: $font-size-14;
  }
}

.subHeader {
  background-color: rgba($color: $green, $alpha: 0.05);
  border-radius: 4px;
  p {
    font-size: $font-size-14;
  }
}

.list {
  font-size: $font-size-14;
}

.editBtn {
  border-radius: 50%;
  border: 1px solid $gray-100;
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
}

.line {
  width: 98%;
  border-bottom: 1px solid $gray-100;
}

.link {
  color: $dark;
  &:hover {
    color: $primary;
  }
}


.status {
  text-align: center;
  border-radius: 4px;
  padding: 0.25rem 1rem;
  font-size: 10px;
  &.active {
      border: 1px solid $green;
      color: $green;
      background-color: rgba($color: $green, $alpha: 0.1);
  }
  &.failed,&.suspended,&.nonRenewing {
      border: 1px solid $red;
      color: $red;
      background-color: rgba($color: $red, $alpha: 0.1);
  }
  &.inTrial,&.pendingSuspension{
      border: 1px solid $orange;
      color: $orange;
      background-color: rgba($color: $orange, $alpha: 0.1);
  }
  &.archived{
    border: 1px solid $gray-500;
    color:$gray-500;
    background-color: rgba($color: $gray-500, $alpha: 0.1);
  }
}

.unitPrice {
  font-size: $font-size-12;
}

@import '../../assets/sass/styleguide/variables';

.stepNumber {
	width: 32px;
	height: 32px;
	border-radius: 50%;
}

.lineSeparator {
	width: 16px;
	height: 2px;
	background-color: $primary;
}
@import '../../assets/sass/components/global/_date-input.scss';

.wrapper {
  label {
    margin-bottom: 2px;
  }
}

.datePickerSheet {
  border: 0;
}

.showWarning {
  border: 1px solid var(--warning) !important;
  border-radius: 0 !important;
}

.openContainer {
  position: absolute;
  height: 40px;
  top: 19px;
  z-index: 99;
  width: calc(100% - 4rem);
}

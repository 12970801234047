@import "../../../../assets/sass/styleguide/variables";
.wrapper {
    top: 30px;
    right: 6px;
    position: absolute;
    box-shadow: $box-shadow-04dp;
    z-index: 10;
    width: 150px;
    font-size: $font-size-14;
    .listItem:hover {
        background-color: $gray-50;
        i {
            color: $primary;
        }
    }
    .link {
        color: $dark;
        cursor: pointer;
    }
    i {
        font-size: $font-size-base;
        color: $gray-500;
    }
}
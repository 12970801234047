@import 'assets/sass/styleguide/variables';

.value {
  padding-left: 8px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex: 1;
  line-height: 20px;
}

.name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.icon {
  font-size: $font-size-20;
}

body[class*=lang-ar] {
  .value {
    padding-left: inherit;
    padding-right: 8px;
  }
}
@import 'assets/sass/styleguide/variables';

.selectMenu {
    position: absolute;
    box-shadow: $box-shadow-04dp; 
    top: 20px;
    width:200px;
    bottom: auto;
    max-height: 200px;
    height: auto;
    z-index:10;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 5px;
    overflow-y:auto;
    overflow-x: hidden;
}
.cutomStyle{
    width:200px;
    background-color: white;
    padding:15px 0;
    // min-height: 60px;
    // position:fixed;
    height: auto;
    z-index: 9999;
}
.option {
    width:200px;
    height: 40px;
    padding-left: 10px;
    padding-top: 5px;
    &:hover {
        background-color:#f4f6f7;
    }
}
.options{
    gap:8px;
}

[dir='rtl'] .selectMenu {
    left: inherit;
    right: 12px;
}

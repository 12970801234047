@import '../../../../assets/sass/styleguide/variables';
.listItem {
    cursor: pointer;
    margin-bottom: 0.25rem;
    color: $gray-500;
    font-size: $font-size-14;
    line-height: 20px;
    i {
        font-size: $font-size-20;
    }
    &:hover {
        color: $steal-teal;
        i {
            color: $steal-teal;
        }
    }
    &.active {
        background-color: $white;
        border-radius: 4px;
        box-shadow: $box-shadow-04dp;
        color: $dark;
        i {
            color: $green;
        }
    }
    .total {
        width: 20px;
        height: 20px;
        background-color: $orange;
        color: $white;
        font-size: $font-size-11;
        line-height: 20px;
        border-radius: 50%;
        text-align: center;
    }
}
@import '../assets/sass/styleguide/variables';

.dot {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 3.3em;
  width: 3.3em;
  line-height: 3.3em;
  background-color: white;
  border-radius: 50%;
  font-weight: bold;
  color:$gray-500;
}

.url{
  font-size: 11px;
  color: $gray-500;
}

.green{
  border: 3px solid $green
}

.blue{
  border: 3px solid $blue
}

.yellow{
  border: 3px solid $yellow;
}

.indigo{
  border: 3px solid $indigo;
}

.co_title {
  font-size: 16px;
  color: $dark;
}

.coIcons{
  font-size: 20px;
}

.hide {
  display: none;
}

.dwnlBtn:hover + .hide {
  margin-top: 95px;
  position: absolute;
  display: block;
  text-align: center;
  width: 103px;
  color: $gray-500;
}
@import 'assets/sass/styleguide/variables';

.statusMenu {
    top: 20px;
    position: absolute;
    right: 0;
    box-shadow: $box-shadow-04dp;
    z-index: 10;
    width: 100%;
    font-size: $font-size-14;
    .listItem:hover {
        background-color: $gray-50;
        i {
            color: $primary;
        }
    }
    .link {
        color: $dark;
        cursor: pointer;
    }
    i 
    {
        font-size: $font-size-base;
        color: $gray-500;
    }
}

body[class*='lang-ar'] {
    .statusMenu {
        right: inherit;
        left: 12px;
    }
}
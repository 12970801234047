@import '../assets/sass/styleguide/variables';

.wrapper {
	height: 100vh;
}

.pageTab {
	background-color: transparent;
	border-radius: 4px;
	color: $gray-500;
	text-transform: uppercase;
	font-weight: 700;
	display: flex;
	justify-content: center;
	align-items: center;
	box-shadow: none;
	border: none;
	&:hover,
	&:focus,
	&.active {
	  background-color: $gray-100;
	  color: $dark;
	  box-shadow: none;
	  border: none;
	  outline: none;
	}
  }

.header {
	border-bottom: 1px solid $gray-100;
}

.content {
	height: calc(100vh - 350px);
}

.emptyList {
	width: 230px;
  }
  
  .iconHistory {
	width: 72px;
	height: 72px;
	background-color: $gray-50;
	border-radius: 50%;
  }
  
  .icon {
	font-size: 2rem;
	color: $gray-300;
  }
  
  .newBtn {
	margin-right: 2px;
	position: absolute;
  }

.clientList{
	margin-bottom: 130px;
}


@import "../../assets/sass/styleguide/variables";

.passwordStrength {
  color: $gray-500;
  font-size: $font-size-13;
  margin-bottom: 8px;

  &.active {
    color: $green;
  }

  span {
    text-transform: unset !important;
  }
}
@import "../assets/sass/styleguide/variables";

.wrapper {
  height: 100vh;
}

.header {
  border-bottom: 1px solid $gray-100;
}

.dot {
  text-align: center;
  height: 3.5em;
  width: 3.5em;
  line-height: 3.5em;
  background-color: white;
  border-radius: 50%;
  display: inline-block;
}

.url{
  font-size: 11px;
	color: $steal-teal;
}

.green{
  border: 3px solid $green
}

.blue{
  border: 3px solid $blue
}

.yellow{
  border: 3px solid $orange;
}
@import "../../assets/sass/styleguide/variables";

.wrapper {
  cursor: pointer;
  border-bottom: 1px solid $gray-50;
  &:hover {
    background-color: $light;
    .deleteBtn {
      visibility: visible;
    }
  }
  &:first-of-type {
    border-top: 8px solid $white;
  }
}

.values {
  font-size: $font-size-14;
  color: $steal-teal;
  span {
    border-radius: 4px;
    text-transform: capitalize;
    &.free {
      background-color: rgba($color: $green, $alpha: 0.1);
      color: $green;
    }
    &.business {
      background-color: rgba($color: $yellow, $alpha: 0.1);
      color: $yellow;
    }
    &.enterprise {
      background-color: rgba($color: $blue, $alpha: 0.1);
      color: $blue;
    }
  }
}

.amount {
  font-size: $font-size-12;
  color: $gray-500;
}

.statusIcon {
  i {
    font-size: $font-size-20;
  }
}

.mail {
  font-size: $font-size-12;
  color: $gray-500;
}

.deleteBtn {
  visibility: hidden;
}

.moreBtn {
  padding: 4px;
  transition: background-color 0.2s ease-in-out;
  border-radius: 50%;
  color: $gray-300;
  font-size: $font-size-20;
  &:hover,
  &.active {
    background-color: $light;
    color: $gray-700;
  }
}

.moreMenu {
  width: 200px;
  top: 40px;
  right: 20px;
}


.enter {
  opacity: 0;
}

.enterActive {
  opacity: 1;
  transition: opacity 0.2s ease-in-out;
}

.exit {
  opacity: 0.2;
}

.exitActive {
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
}

.companyName {
  max-width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.mail {
  font-size: $font-size-11;
  color: $gray-500;
}

.status {
  text-align: center;
  border-radius: 4px;
  padding: 0.25rem 1rem;
  font-size: $font-size-12;
  &.future {
    background-color: rgba($blue, 0.3);
    color: $blue;
  }
  &.active {
    color: $green;
    background-color: rgba($green, 0.3);
  }
  &.cancelled {
    color: $red;
    background-color: rgba($color: $red, $alpha: 0.3);
  }
  &.paused {
    color: $yellow;
    background-color: rgba($color: $yellow, $alpha: 0.3);
  }
  &.inTrial {
    color: $orange;
    background-color: rgba($color: $orange, $alpha: 0.3);
  }
  &.nonRenewing {
    color: $red;
    background-color: rgba($color: $red, $alpha: 0.3);
  }
  &.pending {
    background-color: rgba($color: $orange, $alpha: 0.3);
    color: $orange;
  }
  &.failed {
    background-color: rgba($color: $red, $alpha: 0.3);
    color: $red;
  }
  &.pendingRenewal {
    background-color: rgba($orange, 0.3);
    color: $orange;
  }
  &.overdue {
    background-color: rgba($color: $red, $alpha: 0.3);
    color: $red;
  }
}

@import "../../assets/sass/styleguide/variables";

.sendingOverlay {
	position: absolute;
	z-index: 100;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	background-color: rgba(255, 255, 255, 0.9);
}

.content {
  height: 60px;
  position: relative;
  margin-top: 50px;
}

.box {
  width: 30%;
  text-align: center;
  vertical-align: top;
  margin: auto;
}

.loader {
  position: relative;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  margin: 20px;
  display: inline-block;
  vertical-align: middle;
  border: 7px double $primary;
  animation: ball-turn 1s linear infinite;
  opacity: 0.5;
}

.loader:before,
.loader:after {
  content: "";
  position: absolute;
  width: 12px;
  height: 12px;
  background: $gray-500;
  border-radius: 50%;
  bottom: 0;
  right: 37px;
}

.loader:after {
  left: 37px;
  top: 0;
}

.text {
  color: $steal-teal;
  animation: fade 3s linear infinite;
  text-align: center;
}


@keyframes fade {
  0% {opacity: 1;}
  80% {opacity: 0.6;}
  100% {opacity: 1;}
}

@keyframes ball-turn {
  0% {
      transform: rotate(0deg);
  }
  100% {
      transform: rotate(360deg);
  }
}
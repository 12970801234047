@import '../../assets/sass/styleguide/variables';

.data {
  padding: 1rem 0.75rem 1rem 0;
  color: $steal-teal;
  font-size: $font-size-14;
  vertical-align: middle;
  &:last-child {
      padding-right: 1rem;
  }
}
body[class*="lang-ar"] {
  .data {
    padding: 1rem 0 1rem 0.75rem;
    &:last-child {
        padding-right: initial;
        padding-left: 1rem;
    }
  }
}
@import '../../../../assets/sass/styleguide/variables';
.row {
    cursor: pointer;
    &:hover {
        background-color: $light;
    }
    &:first-child {
        td {
            border-top: 4px solid $white;
        }
    }
}

.icon-schedule-for-delete {
    color: #FFC43D;
    font-size: 20px;
}
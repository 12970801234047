@import "../../../../assets/sass/styleguide/variables";
.sideContent {
    border-radius: 8px 0 0 8px;
}

.name {
    font-size: $font-size-20;
    font-weight: bold;
    text-align: center;
}

.job {
    font-size: $font-size-14;
    color: $gray-500;
    text-align: center;
    line-height: 20px;
}

.link {
    color: $gray-500;
    &:hover {
        color: $dark
    }
}

.subText {
    font-size: $font-size-12;
    color: $gray-500;
    line-height: 16px;
}

.text {
    font-size: $font-size-12;
    line-height: 20px;
}

.logoWrrapper {
    width: 88px;
    height: 88px;
    border-radius: 50%;
    border: 4px solid $white;
    margin: auto;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
        width: 100%;
        height: auto;
    }
}
@import "assets/sass/styleguide/variables";

.emptyList {
  width: 230px;
}

.iconHistory {
  width: 72px;
  height: 72px;
  background-color: $gray-50;
  border-radius: 50%;
}

.icon {
  font-size: 2rem;
  color: $gray-300;
}

@import 'assets/sass/styleguide/variables';

.wrapper {
  .switch {
    position: relative;
    display: inline-block;
    &.md {
      width: 40px;
      height: 24px;
      .slider:before {
        height: 16px;
        width: 16px;
      }
      input:checked + .slider:before {
        transform: translateX(16px);
      }
    }
    &.sm {
      width: 32px;
      height: 20px;
      .slider:before {
        height: 12px;
        width: 12px;
      }
      input:checked + .slider:before {
        transform: translateX(12px);
    }
    }
    input {
      opacity: 0;
      width: 0;
      height: 0;
    }
  }

  .label {
    color: var(--text-third);
    &.checked {
        color: var(--text-main);
    }
  }

  .slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: $gray-300;
      transition: .4s;
  }

  .slider:before {
      position: absolute;
      content: "";
      left: 4px;
      bottom: 4px;
      background-color: $white;
      transition: .4s;
      box-shadow: $box-shadow-04dp;
  }

  input:checked + .slider {
      background-color: var(--primary);
  }

  input:focus + .slider {
      box-shadow: 0 0 1px var(--primary);
  }

  .slider.round {
      border-radius: 100px;
  }

  .slider.round:before {
      border-radius: 50%;
  }

  &.disabled {
    opacity: 0.6;
    pointer-events: none;
  }
}
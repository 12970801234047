@import "../assets/sass/styleguide/variables";

.content {
  height: 100vh;
}

 .card-wpapper {
  border-radius: 8px;
  box-shadow: 0px 2px 8px 0px rgba(93, 114, 140, 0.1),
    0px 3px 4px -2px rgba(93, 114, 140, 0.08);
}

@import '../../assets/sass/styleguide/variables';

.wrapper {
	cursor: pointer;
	border-bottom: 1px solid $gray-50;

	&:hover {
		background-color: $light;

		.deleteBtn {
			visibility: visible;
		}
	}

	&:first-of-type {
		border-top: 8px solid $white;
	}
}

.values {
	font-size: $font-size-14;
	color: $steal-teal;

	span {
		border-radius: 4px;
		text-transform: capitalize;

		&.free {
			background-color: rgba($color: $green, $alpha: 0.1);
			color: $green;
		}

		&.business {
			background-color: rgba($color: $yellow, $alpha: 0.1);
			color: $yellow;
		}

		&.enterprise {
			background-color: rgba($color: $blue, $alpha: 0.1);
			color: $blue;
		}
	}
}

.moreBtn {
	padding: 4px;
	transition: background-color 0.2s ease-in-out;
	border-radius: 50%;
	color: $gray-300;
	font-size: $font-size-20;
	&:hover,
	&.active {
		background-color: $light;
		color: $gray-700;
	}
}


.inactive {
	opacity: 0.4;
}

.status {
	border-radius: 4px;
	padding: 0.25rem 1rem;
	font-size: $font-size-12;

	&.public {
		border: 1px solid $green;
		color: $green;
		background-color: rgba($color: $green, $alpha: 0.1);
	}

	&.private {
		border: 1px solid $orange;
		color: $orange;
		background-color: rgba($color: $orange, $alpha: 0.1);
	}
}

.platform {
	border-radius: 4px;
	padding: 0.25rem 1rem;
	font-size: $font-size-12;

	&.web {
		border: 1px solid $primary;
		color: $primary;
		background-color: rgba($color: $primary, $alpha: 0.1);
	}

	&.mob {
		border: 1px solid $yellow;
		color: $yellow;
		background-color: rgba($color: $yellow, $alpha: 0.1);
	}
}

@import "../../assets/sass/styleguide/variables";

.wrapper{
  border: 1px solid $gray-100;
  h6{
    font-size: 18px;
  }
  color: $steal-teal;
}

.gradient {
  background-color: $gray-50;
  background-image: linear-gradient(
    90deg,
    $gray-100 0px,
    rgba($gray-50, 0.9) 300px,
    $gray-100 500px
  );
  background-size: 2000px;
  animation: shine-lines 1.3s infinite linear;
  opacity: 0.8;
}
@keyframes shine-lines {
  0% {
    background-position: -750px - 370px;
  }
  40%,
  100% {
    background-position: 600px - 370px;
  }
}
@import '../../styleguide/variables';

.react-calendar {
    background-color: #fff;
    min-width: 270px !important;
    min-height: 281px !important;
}

.month-calendar {
    .react-calendar {
        min-height: 210px !important;
    }
}

.react-date-picker, .react-calendar {
    width: 100% !important;
    &.form-control {
        padding: 0 !important;
    }
    button {
        border: 0 !important;
        &:hover, &:focus {
            border: 0 !important;
            box-shadow: 0 !important;
            outline: 0 !important;
        }
    }
    .react-calendar {
        min-height: 256px !important;
    }
}

.react-calendar__tile--now {
    background: none !important;
}

.react-date-picker__inputGroup {
    padding-left: 0.5rem !important;
    select {
        color: var(--text-main) !important;
        -moz-appearance: none !important;
        -webkit-appearance: none !important;
        pointer-events: none !important;
        &:focus {
            box-shadow: 0 !important;
            outline: 0 !important;
        }
    }
    input {
        color: var(--text-main) !important;
    }
}

.form-control-sm {
    .react-date-picker__inputGroup {
        position: relative !important;
        height: 30px !important;
        top: -3px !important;
    }
}

// .is-invalid {
//     .react-date-picker__wrapper {
//         width: 80% !important;
//     }
// }

.react-date-picker__inputGroup__input {
    font-size: 16px !important;
}

.react-date-picker__inputGroup__input:invalid {
    background: none !important;
}

.react-date-picker__wrapper {
    width: 100% !important;
    border: 0 !important;
    &:hover, &:focus {
        border: 0 !important;
        box-shadow: 0 !important;
        outline: 0 !important;
    }
    button {
        display: flex !important;
        &:focus {
            border: 0 !important;
            box-shadow: 0 !important;
            outline: 0 !important;
        }
        &:hover {
            color: var(--text-second) !important;
        }
    }

    .react-date-picker__button {
        align-items: center;
    }

    .icon-calendar-dates {
        color: var(--text-third) !important;
        font-size: 1.25rem !important;
    }

    .icon-cross-x {
        font-size: 0.875rem !important;
        color: var(--text-fifth) !important;
    }

    input {
        &:hover, &:focus {
            border: none !important;
            box-shadow: none !important;
            outline: none !important;
        }
    }
}

.form-control-sm .react-date-picker__wrapper {
    height: 32px !important;
}

.react-date-picker__calendar {
    width: 270px !important;
    z-index: 100 !important;
    .react-calendar {
        border: 0 !important;
        border-radius: 4px !important;
        box-shadow: $box-shadow-06dp !important;
    }
}

.btm-box {
    .react-date-picker__calendar--open {
        top: 100% !important;
        bottom: unset !important;
    }
}


.react-calendar {
    border: 0 !important;
}

abbr {
    font-size: 0.75rem !important;
}

.react-calendar__month-view__weekdays__weekday {
    padding: .5em;
}

.react-calendar__month-view__weekdays {
    border-bottom: 1px solid $gray-100 !important;
    font-size: .75em;
    font-weight: 700;
    text-align: center;
    text-transform: uppercase;
    abbr {
        font-size: 0.68rem !important;
        color: var(--text-fifth) !important;
        font-weight: normal !important;
        text-decoration: none !important;
    }
}

.react-calendar__navigation {
    display: flex;
    height: 44px;
    padding-top: 0.25rem !important;
    button {
        background-color: transparent !important;
        &:disabled {
            pointer-events: none !important;
            background-color: transparent !important;
        }
        &:enabled {
            &:hover,
            &:focus {
                background-color: transparent !important;
            }
        }
    }
    .react-calendar__navigation__prev2-button,
    .react-calendar__navigation__next2-button {
        display: none !important;
    }
    .react-calendar__navigation__prev-button,
    .react-calendar__navigation__next-button {
        position: relative !important;
        color: transparent !important;
        &:after {
            content: '\ea3c' !important;
            font-family: 'palmhr', sans-serif !important;
            display: inline-block !important;
            position: absolute !important;
            color: var(--text-fifth) !important;
            width: 32px !important;
            height: 40px !important;
            font-size: 1rem !important;
            right: 4px !important;
        }
        &:hover:after {
            color: var(--text-third) !important;
        }
    }
    .react-calendar__navigation__prev-button {
        &:after {
            content: '\ea3d' !important;
            right: -4px !important;
        }
    }
    .react-calendar__navigation__label {
        font-size: 0.875rem !important;
        color: var(--text-main) !important;

        &:hover {
            color: var(--primary) !important;
        }

        &:disabled  {
            color: var(--text-fifth) !important;
        }
    }
}

.react-calendar__navigation {
    margin-bottom: 0.25rem !important;
    button {
        min-width: 32px !important;
    }
}

.react-calendar__navigation__arrow {
    color: var(--text-third) !important;
}

.react-calendar__month-view,
.react-calendar__year-view__months,
.react-calendar__decade-view {
    padding: 0 0.5rem 0.812rem !important;
}

.react-calendar__month-view__days {
    padding-top: 0.5rem !important;
}

.react-calendar__month-view__days__day,
.react-calendar__month-view__days__day--weekend {
    color: var(--text-main) !important;
}

.react-calendar__month-view__days__day--neighboringMonth {
    color: var(--text-third) !important;
}

.react-calendar__tile {
    background-color: #fff;
    border-radius: 14px !important;
    padding: 0.15rem 0.25rem !important;
    margin: 0.2rem 0 !important;

    &.react-calendar__tile--active, &.react-calendar__tile--hasActive {
        background-color: var(--primary) !important;
        color: $white !important;

        &:hover {
            background-color: var(--primary) !important;
            filter: brightness(90%) !important;
        }
    }

    &:disabled {
        background-color: transparent !important;
        color: $gray-200 !important;
    }
}

.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
    background-color: $gray-100 !important;

    &.react-calendar__tile--active, &.react-calendar__tile--hasActive {
        background-color: var(--primary) !important;
        filter: brightness(90%) !important;
    }
}

.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view__decades .react-calendar__tile {
    font-size: $font-size-13;
    padding: 0.5rem 0.5rem !important;
    border-radius: 16px !important;
    color: $dark !important;
    &.react-calendar__tile--active, &.react-calendar__tile--hasActive {
        background-color: var(--primary) !important;
        color: $white !important;

        &:hover {
            background-color: var(--primary) !important;
            filter: brightness(90%) !important;
        }
    }
    &:disabled {
        color: var(--text-fifth) !important;
    }
}

body[class*="lang-ar"] {
    .react-calendar__navigation__arrow {
        transform: rotate(180deg) !important;
    }
    .react-date-picker__inputGroup {
        padding-right: 8px;
    }
}

.react-date-picker.is-warning {
    .react-date-picker__wrapper {
        .react-date-picker__inputGroup {
            .react-date-picker__inputGroup__input {
                color: var(--orange) !important;
            }
        }
    }
}

.react-date-picker--disabled {
    background-color: $gray-100 !important;
    color: var(--text-third) !important;

    button {
        visibility: hidden !important;
    }
}

.event-calendar {
    .react-calendar {
        min-width: 278px !important;
        max-width: 350px !important;
    }
    .react-calendar__navigation {
        border: 1px solid $gray-100;
        border-radius: 8px;
        .react-calendar__navigation__label {
            font-weight: 700 !important;
        }
    }
    .react-calendar__month-view__weekdays {
        border-bottom: none !important;
        padding-top: 1rem;
    }
    .react-calendar__tile {
        padding: 10px  !important;
        border-left: 2px solid $white;
        border-right: 2px solid $white;
        margin-bottom: 10px !important;
        margin-top: 10px !important;
        position: relative;
        overflow: visible !important;
        .event {
            position: absolute;
            bottom: -14px;
            right: 50%;
            margin-right: -8px;
            font-size: $font-size-base;
            color: var(--indigo);
        }
        &.react-calendar__month-view__days__day {
            border-radius: 50% !important;
        }
    }
    .react-calendar button:hover, .react-calendar button:focus {
        border-left: 2px solid $white !important;
        border-right: 2px solid $white !important;
    }
    .react-calendar__tile:enabled:focus.react-calendar__tile--active {
        filter: unset !important;
    }

}

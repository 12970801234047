@import 'assets/sass/styleguide/variables';

.sendingOverlay {
    position: absolute;
    z-index: 9999;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba($color: $white, $alpha: 0.95);

    &.displayNone {
        display: none !important;
    }
}

.content {
    width: 312px;

    > div {
        width: 160px;
        margin: auto;
    }
}

.text {
    font-size: $font-size-base;
    line-height: 1.5rem;
    opacity: 0;
    white-space: normal;
    animation: show 100ms ease-in-out 500ms forwards;
    position: relative;
    text-align: center;
    color: var(--secondary);
    width: auto !important;

    .upsText {
        color: var(--danger);
        font-weight: bold;
        font-size: $font-size-24;
        display: block;
    }
}

.xIcon {
    position: absolute;
    top: 16px;
    right: 16px;
}

@keyframes show {
    to {
        opacity: 1;
    }
}

.icon{
    font-size: $font-size-24;
    color: var(--danger);
}

.btnBox {
    position: absolute;
    right: 32px;
    top: 16px;
}
@import "../assets/sass/styleguide/variables";


.wrapper {
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  min-height: calc(100vh - 88px);
  overflow: hidden;
  z-index: 102;
  top: 0;
  left: 0;
  opacity: 1;
  transition: width 0.5s ease-in-out, opacity 0.5s;

  &.minimized {
    width: 56px;

    .backdrop {
      opacity: 0;
      width: 56px; // If element has with value of 100% it will overlay entire window
    }
  }
}

.backdrop {
  background-color: rgba(4, 51, 68, 0.4);
  transition: opacity 0.5s ease-in-out;
}

body[class*="lang-ar"] {
  .wrapper {
    left: inherit;
    right: 0;
  }

  .main {
    margin-left: inherit;
    margin-right: 216px;
    transform-origin: left;
  }

  .backdrop {
    left: inherit;
    right: 0;
  }

  .enter {
    transform: translateX(100%);
  }

  .enterActive {
    transform: translateX(0);
  }

  .exit {
    transform: translateX(0);
  }

  .exitActive {
    transform: translateX(100%);
  }
}

@import "assets/sass/styleguide/variables";

.wrapper {
  background-color: var(--ec-sidebar-background);
  width: 216px;
  height:100vh;
  opacity: 1;
  transition: width 0.3s ease-in-out, opacity 0.3s;

  .navSection {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 10px;
    height: 100%;
    padding: 0.7rem;
  }
  .navMain {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 10px;
    height: 60%;
  }
  .navFooter{
    height: 40%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    gap: 10px;
  }

  &.minimized {
    width: 56px;
    white-space: nowrap;
    .title {
      opacity: 0;
    }
  }
  .grayIcon {
    color: var(--text-third);
  }
}

body[class*="lang-ar"] {
  .wrapper {
    left: inherit !important;
  }
}

@import "../assets/sass/styleguide/variables";

.tr {
  cursor: pointer;
  border-bottom: 1px solid $gray-100;
  &:first-child {
    border-top: 4px solid $white;
  }
  &:hover{
    background-color: $light;
    color: $dark;
  }
  button {
    opacity: 0;
    transition: all 0.2s ease-in-out;
  }
  &:hover {
    button {
      opacity: 1;
    }
  }
  td {
    width: 25%;
  }
  td:first-child {
    padding-left: 2rem;
    div {
      max-width: 260px;
    }
  }

  .arabic {
    direction: rtl;
  }
}
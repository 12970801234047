@import "../../assets/sass/styleguide/variables";
.line {
    width: 98%;
    border-bottom: 1px solid $gray-100;
}

.link {
    color: $dark;
    &:hover {
        color: $primary;
    }
}
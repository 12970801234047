@import "../assets/sass/styleguide/variables";

.link {
  border-radius: 4px;
  background-color: transparent;
  border: none;
  transition: background-color 0.3s ease;

  .text {
    white-space: nowrap;
    overflow: hidden;
    font-size: 0.875rem;
    color: var(--text-fifth);
  }

  .iconWrapper {
    height: 1.5rem;
  }

  i {
    font-size: 1.5rem;
    color: aliceblue;
  }


  &:hover {
    text-decoration: none;
    background-color: darken($gray-900, 3%);
    
    .text {
      color: $gray-100;
    }
  }

  &:focus {
    border: none;
    outline: none;
    box-shadow: none;
  }

  &.active {
    background-color: $gray-900;
    color:white;
  }
}

@import '../../assets/sass/styleguide/variables';

.header {
	position: absolute;
	width: 100%;
	height: 152px;
	background-color: #F5F9FA;
	background-image: url(../../assets/img/rateus.png);
	background-repeat: no-repeat;
	background-position-x: right;
	border-radius: 15px 15px 0 0;


	.closeButton {
		position: absolute;
		font-size: 14px;
		top: 30px;
		left: 25px;
		color:#93A5B1;
	}

	img {
		width: 100%;
		max-height: 240px;
		position: relative;
		top: -1px;
	}

	.title {

		margin-left: 25px;

		span {
			position: absolute;
			top: 83px;
			font-size: 17px;
			line-height: 22px;
			font-weight: bold;
			color: $dark;
		}
	}
}

.ul {
	li {
		margin-left: 40px;
		position: relative;
		font-size: 15px;
		font-weight: 400;
		color: $steal-teal;
		margin-bottom: 1rem;
		line-height: 20px;

		&::before {
			font-family: 'palmhr';
			content: '\ea55';
			font-size: 15px;
			color: $primary;
			position: absolute;
			height: 8px;
			width: 8px;
			z-index: 1;
			left: -20px;
			top: 0;
		}

		&.noInfo {
			font-weight: bold;
			padding-left: 0;

			&::before {
				display: none;
			}
		}
	}
}

.emptyList {
	width: 164px;
}

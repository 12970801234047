@import "../../assets/sass/styleguide/variables";

.tr {
  text-transform: uppercase;
  font-size: $font-size-11;
  color: $steal-teal;
  background-color: $gray-50;
  .th {
    background-color: $gray-50;
    font-weight: normal;
    padding: 12px 0;
    line-height: 18px;
    &:first-child {
      border-radius: 4px 0 0 4px;
      padding-left: 2rem;
    }
    &:last-child {
      border-radius: 0 4px 4px 0;
    }
  }
}

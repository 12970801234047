@import "../assets/sass/styleguide/variables";

.wrapper{
  border: 1px solid $gray-100;
  border-radius: 8px;
  padding: 1rem;
}

.title{
  padding-bottom: 1px;
  font-size: 18px;
  font-weight: bold;
  color: $gray-500;
}

.gradient {
  background-color: $gray-50;
  background-image: linear-gradient(
    90deg,
    $gray-100 0px,
    rgba($gray-50, 0.9) 300px,
    $gray-100 500px
  );
  background-size: 2000px;
  animation: shine-lines 1.3s infinite linear;
  opacity: 0.8;
}

@keyframes shine-lines {
  0% {
    background-position: -750px - 370px;
  }
  40%,
  100% {
    background-position: 600px - 370px;
  }
}

.dot {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 3.3em;
  width: 3.3em;
  line-height: 3.3em;
  background-color: white;
  border-radius: 50%;
  font-weight: bold;
  color:$gray-500;
}

.url{
  font-size: 11px;
  color: $gray-500;
}

.co_title {
  font-size: 16px;
  color: $dark;
}

tr:hover{
  background-color: #fff
}

.co_title{
  font-size: 16px;
  color: #5d728c;
}

a, .url{
font-size: 14px;
color: $gray-500;
word-break: break-all;
}

.see {
  cursor: pointer
}

#company{
  margin:  0 !important;
  padding:  0 !important;
}

.backdrop {
  opacity: 0;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: -2;
  left: 0;
  top: 0;
  background-color: rgba(40, 75, 100, 0.4);
  backdrop-filter: blur(2px);
  transition: opacity 0.5s ease-in-out;
  &.active {
    opacity: 1;
    z-index: 1000;
  }
}
@import "../../../../assets/sass/styleguide/variables";
.wrapper {
    height: 100%;
    border-radius: 8px;
    box-shadow: $box-shadow-04dp;
    overflow-x: hidden;
}

.img {
    display: block;
    margin: auto;
    width: 140px;
    height: auto;
}

.nameImg {
    height: 16px;
    width: auto;
    display: block;
    margin: auto;
}

.triaInfo {
    font-size: $font-size-13;
    color: $gray-500;
}

.title {
    color: $steal-teal;
    font-size: $font-size-14;
    font-weight: 700;
}

.label {
    font-size: 0.6875rem;
    text-transform: uppercase;
    padding: 0 0.25rem;
    font-weight: bold;
    letter-spacing: 0.5px;
    line-height: 16px;
    color: #a7b5bf;
}

.values {
    font-size: $font-size-14;
    color: $steal-teal;
    line-height: 20px;
}

.status {
    text-align: center;
    border-radius: 4px;
    padding: 0.25rem 1rem;
    font-size: 10px;
    &.active {
        border: 1px solid $green;
        color: $green;
        background-color: rgba($color: $green, $alpha: 0.1);
    }
    &.cancelled,&.suspended {
        border: 1px solid $red;
        color: $red;
        background-color: rgba($color: $red, $alpha: 0.1);
    }
    &.inTrial,&.pendingSuspension{
        border: 1px solid $orange;
        color: $orange;
        background-color: rgba($color: $orange, $alpha: 0.1);
    }
}

.couponsContainer, .addonsContainer {
    max-height: 338px;
    min-height: 338px;
    height: 100%;
    overflow: hidden; 
  }
  
  .couponsContainer:hover, .addonsContainer:hover {
    overflow: auto; 
  }

.invoiceBtn {
    font-size: $font-size-14;
    cursor: pointer;
    font-weight: bold;
}
@import "../assets/sass/styleguide/variables";

.sendingOverlay {
  position: absolute;
  z-index: 100;
  top: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.9);
}

.content {
  height: 150px;
  position: relative;
  display: flex;
  width: 175px;
}

.box {
  position: absolute;
  height: 110px;
  width: 110px;
  left: 29px;
}

.circle {
  height: 90%;
  width: 90%;
  border-radius: 100vw;
  border-left: solid 10px $green;
  border-right: solid 10px $green;
  border-top: solid 10px #2fbe7a6b;
  border-bottom: solid 10px $green;
  animation: spin 500ms linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(-360deg);
  }
}

@import '../assets/sass/styleguide/variables';

.text {
  font-size: $font-size-13;
}

.numb{
  font-size: $font-size-base;
}

.plan{
  font-size: $font-size-14;
}

.math{
  font-size: $font-size-12;
}

.btn {
  cursor: pointer;
  &:hover {
    color: $primary;
  }
}
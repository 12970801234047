@import '../../../../assets/sass/styleguide/variables';
.row {
    cursor: pointer;
    &:hover {
        background-color: $light;
    }
    &:first-child {
        td {
            border-top: 4px solid $white;
        }
    }
}

.status {
    padding: 0 0.5rem;
    border-radius: 4px;
    text-transform: uppercase;
    font-size: $font-size-11;
    line-height: 18px;
    font-weight: bold;
    min-width: 104px;
    display: inline-block;
    &.paid {
        border: 1px solid $green;
        color: $green;
    }
    &.posted {
        border: 1px solid $gray-500;
        color: $gray-500;
    }
    &.notPaid {
        border: 1px solid $red;
        color: $red;
    }
    &.paymentDue {
        border: 1px solid $orange;
        color: $orange;
    }
}
.clientList{
	margin-bottom: 130px;
}

.wrapper {
	height: 100vh;
}

.fixedHeader th {
	position: sticky;
	  top: 0;
	  z-Index: 1
  }
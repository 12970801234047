@import "../../../../assets/sass/styleguide/variables";
.line {
    width: 98%;
    border-bottom: 1px solid $gray-100;
}

.notes {
    button {
        opacity: 0;
        transition: all 0.2s ease-in-out;
    }
    &:hover {
        button {
            opacity: 1;
        }
    }
}
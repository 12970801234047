@import "../../../assets/sass/styleguide/variables";
.wrapper {
    border-radius: 0 8px 8px 0;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    .content {
        flex-grow: 1;
    }
    .title {
        font-weight: bold;
        font-size: $font-size-20;
        line-height: 24px;
        text-transform: capitalize;
    }
    .package {
        p:first-child {
            color: $blue;
        }
        &:after {
            position: absolute;
            top: 38px;
            right: -3px;
            font-size: $font-size-base;
            color: $gray-500;
            content: '+';
        }
    }
    .additions {
        &:after {
            position: absolute;
            top: 38px;
            right: -3px;
            font-size: $font-size-base;
            color: $gray-500;
            content: '-';
        }
        p:first-child {
            color: $green;
        }
    }
    .deductions {
        p:first-child {
            color: $red;
        }
        &:after {
            position: absolute;
            top: 38px;
            right: -3px;
            font-size: $font-size-base;
            color: $gray-500;
            content: '=';
        }
    }
    .payment {
        border: none;
    }
}

body[class*="lang-ar"] {
    .wrapper {
        border-radius: 8px 0 0 8px;
    }
    .package::after,
    .additions::after,
    .deductions::after {
        right: initial;
        left: -3px;
    }
}
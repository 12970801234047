@import '../../../../assets/sass/styleguide/variables';
.emptyList {
    width: 230px;
}

.iconHistory {
    width: 88px;
    height: 88px;
    background-color: $gray-50;
    border-radius: 50%;
}

.icon {
    font-size: 2.5rem;
    color: $gray-300;
}
@font-face {
  font-family: 'palmhr';
  src:  url('fonts/palmhr.eot?iih37a');
  src:  url('fonts/palmhr.eot?iih37a#iefix') format('embedded-opentype'),
  url('fonts/palmhr.ttf?iih37a') format('truetype'),
  url('fonts/palmhr.woff?iih37a') format('woff'),
  url('fonts/palmhr.svg?iih37a#palmhr') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'palmhr' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-Icon_Menu:before {
  content: "\e9d1";
}
.icon-heart_filled:before {
  content: "\e9d0";
}
.icon-AI_Assistant:before {
  content: "\e9cf";
  color: #93a5b1;
}
.icon-user-check:before {
  content: "\e9ce";
  color: #93a5b1;
}
.icon-Import:before {
  content: "\e9cc";
  color: #93a5b1;
}
.icon-Star_filled:before {
  content: "\e9cb";
  color: #FFC43D;
}
.icon-Reorder:before {
  content: "\e9c8";
  color: #93a5b1;
}
.icon-card-density:before {
  content: "\e9c7";
  color: #93a5b1;
}
.icon-paid:before {
  content: "\e9c4";
  color: #284b64;
}
.icon-unpaid:before {
  content: "\e9c5";
  color: #284b64;
}
.icon-alarm-no:before {
  content: "\e9c6";
  color: #284b64;
}
.icon-shield-no:before {
  content: "\e9c3";
}
.icon-arrow-bold-undo:before {
  content: "\e9c2";
}
.icon-arrow-right-curved:before {
  content: "\e9ba";
}
.icon-benefit-gosi-deduction:before {
  content: "\e9bc";
}
.icon-Leave-Encashment:before {
  content: "\e9bd";
}
.icon-settlement-end-of-service:before {
  content: "\e9be";
}
.icon-tax-policy:before {
  content: "\e9bf";
}
.icon-circle-minus-manual-deduction:before {
  content: "\e9c0";
}
.icon-circle-plus-manual-addition:before {
  content: "\e9c1";
}
.icon-plus-minus:before {
  content: "\e9b9";
}
.icon-rework:before {
  content: "\e9b8";
  color: #93a5b1;
}
.icon-row-density:before {
  content: "\e9b7";
}
.icon-city:before {
  content: "\e9ac";
}
.icon-po-box:before {
  content: "\e9b0";
}
.icon-prisoner:before {
  content: "\e9b1";
}
.icon-street:before {
  content: "\e9b2";
}
.icon-student:before {
  content: "\e9b3";
}
.icon-village:before {
  content: "\e9b5";
}
.icon-zip:before {
  content: "\e9b6";
}
.icon-icon:before {
  content: "\e9a2";
}
.icon-icon-1:before {
  content: "\e9a3";
}
.icon-icon-2:before {
  content: "\e9a4";
}
.icon-icon-3:before {
  content: "\e9a5";
}
.icon-icon-4:before {
  content: "\e9a6";
}
.icon-icon-5:before {
  content: "\e9a7";
}
.icon-icon-6:before {
  content: "\e9a8";
}
.icon-icon-7:before {
  content: "\e9a9";
}
.icon-icon-8:before {
  content: "\e9aa";
}
.icon-icon-9:before {
  content: "\e9ab";
}
.icon-objective-weight:before {
  content: "\e99e";
}
.icon-review:before {
  content: "\e9a1";
}
.icon-policy:before {
  content: "\e99c";
  color: #93a5b1;
}
.icon-check-list:before {
  content: "\e99d";
}
.icon-asset-1:before {
  content: "\e982";
}
.icon-asset-2:before {
  content: "\e983";
}
.icon-asset-3:before {
  content: "\e984";
}
.icon-asset-4:before {
  content: "\e985";
}
.icon-asset-5:before {
  content: "\e988";
}
.icon-asset-6:before {
  content: "\e989";
}
.icon-asset-7:before {
  content: "\e98a";
}
.icon-asset-8:before {
  content: "\e98d";
}
.icon-asset-9:before {
  content: "\e98e";
}
.icon-asset-10:before {
  content: "\e990";
}
.icon-asset-11:before {
  content: "\e991";
}
.icon-asset-12:before {
  content: "\e997";
}
.icon-asset-13:before {
  content: "\e998";
}
.icon-asset-14:before {
  content: "\e999";
}
.icon-asset-15:before {
  content: "\e99a";
}
.icon-asset-16:before {
  content: "\e99b";
}
.icon-zoom-in:before {
  content: "\e98c";
}
.icon-shift:before {
  content: "\e98b";
}
.icon-unlock:before {
  content: "\e978";
}
.icon-mouse:before {
  content: "\e987";
}
.icon-grid--customize-columns:before {
  content: "\e9ca";
}
.icon-Google-Play-LOGO .path1:before {
  content: "\e97a";
  color: rgb(0, 0, 0);
}
.icon-Google-Play-LOGO .path2:before {
  content: "\e97b";
  margin-left: -0.9228515625em;
  color: rgb(0, 0, 0);
}
.icon-Google-Play-LOGO .path3:before {
  content: "\e97c";
  margin-left: -0.9228515625em;
  color: rgb(0, 0, 0);
}
.icon-Google-Play-LOGO .path4:before {
  content: "\e97d";
  margin-left: -0.9228515625em;
  color: rgb(0, 0, 0);
}
.icon-Google-Play-LOGO .path5:before {
  content: "\e97e";
  margin-left: -0.9228515625em;
  color: rgb(0, 0, 0);
  opacity: 0.2;
}
.icon-Google-Play-LOGO .path6:before {
  content: "\e97f";
  margin-left: -0.9228515625em;
  color: rgb(0, 0, 0);
  opacity: 0.12;
}
.icon-Google-Play-LOGO .path7:before {
  content: "\e980";
  margin-left: -0.9228515625em;
  color: rgb(0, 0, 0);
  opacity: 0.12;
}
.icon-Google-Play-LOGO .path8:before {
  content: "\e981";
  margin-left: -0.9228515625em;
  color: rgb(255, 255, 255);
  opacity: 0.25;
}
.icon-apple-logo:before {
  content: "\e979";
}
.icon-request-excuse:before {
  content: "\e977";
  color: #93a5b1;
}
.icon-import:before {
  content: "\e92d";
}
.icon-file-create:before {
  content: "\e96a";
}
.icon-dropbox:before {
  content: "\e937";
}
.icon-file-doc:before {
  content: "\e938";
}
.icon-file-jpeg:before {
  content: "\e939";
}
.icon-file-jpg:before {
  content: "\e93a";
}
.icon-file-other:before {
  content: "\e93b";
}
.icon-file-pdf:before {
  content: "\e93c";
}
.icon-file-png:before {
  content: "\e93d";
}
.icon-google-docs:before {
  content: "\e93e";
}
.icon-google-drive:before {
  content: "\e962";
}
.icon-google-sheets:before {
  content: "\e963";
}
.icon-google-slides:before {
  content: "\e964";
}
.icon-move-file:before {
  content: "\e965";
}
.icon-sharepoint:before {
  content: "\e966";
}
.icon-googleplay:before {
  content: "\e935";
}
.icon-appstore:before {
  content: "\e936";
}
.icon-menu:before {
  content: "\e900";
}
.icon-video:before {
  content: "\e92b";
}
.icon-building:before {
  content: "\e92a";
}
.icon-custom-1:before {
  content: "\e91e";
}
.icon-custom-2:before {
  content: "\e91f";
}
.icon-custom-3:before {
  content: "\e920";
}
.icon-custom-4:before {
  content: "\e921";
}
.icon-custom-5:before {
  content: "\e928";
}
.icon-custom-6:before {
  content: "\e92f";
}
.icon-custom-7:before {
  content: "\e930";
}
.icon-custom-8:before {
  content: "\e931";
}
.icon-custom-9:before {
  content: "\e932";
}
.icon-custom-10:before {
  content: "\e933";
}
.icon-basic:before {
  content: "\e903";
}
.icon-premium:before {
  content: "\e904";
}
.icon-enterprise:before {
  content: "\e91d";
}
.icon-google:before {
  content: "\e902";
}
.icon-menu-minimize:before {
  content: "\e901";
}
.icon-pencil-create:before {
  content: "\e905";
}
.icon-copy:before {
  content: "\e929";
}
.icon-pencil-edit:before {
  content: "\e906";
}
.icon-request-timeoff:before {
  content: "\e907";
}
.icon-request-hr:before {
  content: "\e917";
}
.icon-request-pay:before {
  content: "\e918";
}
.icon-loan:before {
  content: "\e91b";
}
.icon-request-general:before {
  content: "\e908";
}
.icon-request-task:before {
  content: "\e909";
}
.icon-tasks-2:before {
  content: "\e934";
}
.icon-request-sick:before {
  content: "\e90b";
}
.icon-request-unpaid:before {
  content: "\e90c";
}
.icon-request-vacation:before {
  content: "\e90e";
}
.icon-vacation-salary:before {
  content: "\e91c";
}
.icon-request-business:before {
  content: "\e91a";
}
.icon-request-remote:before {
  content: "\e90f";
}
.icon-request-special:before {
  content: "\e919";
}
.icon-request-doc:before {
  content: "\e910";
}
.icon-request-ticket:before {
  content: "\e911";
}
.icon-request-visa:before {
  content: "\e912";
}
.icon-request-transport:before {
  content: "\e913";
}
.icon-request-asset:before {
  content: "\e914";
}
.icon-request-accomodation:before {
  content: "\e916";
}
.icon-request-suggestion:before {
  content: "\e915";
}
.icon-cross:before {
  content: "\e90a";
}
.icon-image:before {
  content: "\e90d";
}
.icon-file-text:before {
  content: "\e922";
}
.icon-file-delete:before {
  content: "\e923";
}
.icon-file-download:before {
  content: "\e925";
}
.icon-file-csv:before {
  content: "\e92c";
}
.icon-file-xls:before {
  content: "\e92e";
}
.icon-note:before {
  content: "\e924";
}
.icon-clipboard-text:before {
  content: "\e926";
}
.icon-note-text:before {
  content: "\e927";
}
.icon-wallet:before {
  content: "\e93f";
}
.icon-salary-advance:before {
  content: "\e943";
}
.icon-salary:before {
  content: "\e940";
}
.icon-finance:before {
  content: "\e944";
}
.icon-expense-claim:before {
  content: "\e945";
}
.icon-strongbox:before {
  content: "\e941";
}
.icon-bank:before {
  content: "\e946";
}
.icon-dollar:before {
  content: "\e94d";
}
.icon-sar:before {
  content: "\e94f";
}
.icon-call-phone:before {
  content: "\e942";
}
.icon-pin:before {
  content: "\e947";
}
.icon-pin-round:before {
  content: "\e94c";
}
.icon-route:before {
  content: "\e948";
}
.icon-direction-45:before {
  content: "\e94b";
}
.icon-send:before {
  content: "\e949";
}
.icon-compas:before {
  content: "\e94a";
}
.icon-time:before {
  content: "\e94e";
}
.icon-alarm-checked:before {
  content: "\e950";
}
.icon-grace-out:before {
  content: "\e95d";
}
.icon-grace-in:before {
  content: "\e960";
}
.icon-watch:before {
  content: "\e951";
}
.icon-bell:before {
  content: "\e954";
}
.icon-timer:before {
  content: "\e952";
}
.icon-calendar-dates:before {
  content: "\e953";
}
.icon-calendar:before {
  content: "\e95a";
}
.icon-calendar-checked:before {
  content: "\e955";
}
.icon-timesheets:before {
  content: "\e95c";
}
.icon-calendar-delete:before {
  content: "\e961";
}
.icon-moon:before {
  content: "\e95b";
}
.icon-holiday:before {
  content: "\e969";
}
.icon-monitor1:before {
  content: "\e956";
}
.icon-laptop:before {
  content: "\e957";
}
.icon-mobile:before {
  content: "\e958";
}
.icon-camera:before {
  content: "\e959";
}
.icon-download:before {
  content: "\e95e";
}
.icon-cloud-upload:before {
  content: "\e95f";
}
.icon-arrow-bold-reply:before {
  content: "\e967";
}
.icon-arrow-bold-forward:before {
  content: "\e968";
}
.icon-comment-text:before {
  content: "\e96b";
}
.icon-comment:before {
  content: "\e96c";
}
.icon-comment-plus:before {
  content: "\e96d";
}
.icon-comment-minus:before {
  content: "\e96e";
}
.icon-comment-checked:before {
  content: "\e96f";
}
.icon-comment-delete:before {
  content: "\e970";
}
.icon-question:before {
  content: "\e971";
}
.icon-user:before {
  content: "\e972";
}
.icon-people:before {
  content: "\e976";
}
.icon-user-add:before {
  content: "\e973";
}
.icon-user-remove:before {
  content: "\e974";
}
.icon-department:before {
  content: "\e975";
}
.icon-search:before {
  content: "\e986";
}
.icon-lock:before {
  content: "\e98f";
}
.icon-options:before {
  content: "\e992";
}
.icon-more-horizontal:before {
  content: "\e993";
}
.icon-settings:before {
  content: "\e994";
}
.icon-eye:before {
  content: "\e995";
}
.icon-eye-no:before {
  content: "\e996";
}
.icon-gift:before {
  content: "\e99f";
}
.icon-pos:before {
  content: "\e9a0";
}
.icon-trash:before {
  content: "\e9ad";
}
.icon-case:before {
  content: "\e9ae";
}
.icon-resume-work:before {
  content: "\e9af";
}
.icon-verified:before {
  content: "\e9b4";
}
.icon-list:before {
  content: "\e9bb";
}
.icon-globe:before {
  content: "\e9c9";
}
.icon-paperclip:before {
  content: "\e9cd";
}
.icon-sun:before {
  content: "\e9d4";
}
.icon-star:before {
  content: "\e9d8";
}
.icon-circle-star:before {
  content: "\e9d7";
}
.icon-alert-triangle:before {
  content: "\ea07";
}
.icon-alert-triangle-fill:before {
  content: "\ea1e";
}
.icon-notification:before {
  content: "\ea08";
}
.icon-notification-fill:before {
  content: "\ea1f";
}
.icon-question-circle:before {
  content: "\ea09";
}
.icon-plus:before {
  content: "\ea0a";
}
.icon-minus:before {
  content: "\ea0b";
}
.icon-exit:before {
  content: "\ea19";
}
.icon-info-fill:before {
  content: "\ea0c";
}
.icon-info:before {
  content: "\ea1c";
}
.icon-circle-plus:before {
  content: "\ea13";
}
.icon-circle-plus1:before {
  content: "\ea18";
}
.icon-circle-minus:before {
  content: "\ea14";
}
.icon-circle-minus-fill:before {
  content: "\ea1a";
}
.icon-circle-x:before {
  content: "\ea0d";
}
.icon-circle-block:before {
  content: "\ea0e";
}
.icon-cross-x:before {
  content: "\ea0f";
}
.icon-circle-checked:before {
  content: "\ea10";
}
.icon-circle-checked-fill:before {
  content: "\ea16";
}
.icon-check-marks:before {
  content: "\ea11";
}
.icon-check-marks-fill:before {
  content: "\ea1d";
}
.icon-check-mark:before {
  content: "\ea12";
}
.icon-checkmark:before {
  content: "\ea15";
}
.icon-link:before {
  content: "\ea17";
}
.icon-link1:before {
  content: "\ea1b";
}
.icon-refresh-ccw:before {
  content: "\ea2e";
}
.icon-rotate-ccw:before {
  content: "\ea2f";
}
.icon-arrow-top:before {
  content: "\ea32";
}
.icon-arrow-right:before {
  content: "\ea34";
}
.icon-arrow-bottom:before {
  content: "\ea36";
}
.icon-arrow-left:before {
  content: "\ea38";
}
.icon-chevron-top:before {
  content: "\ea3a";
}
.icon-chevron-bottom:before {
  content: "\ea3b";
}
.icon-chevron-right:before {
  content: "\ea3c";
}
.icon-chevron-left:before {
  content: "\ea3d";
}
.icon-arrow-dropdown:before {
  content: "\ea3e";
}
.icon-circle-chevron-top:before {
  content: "\ea41";
}
.icon-circle-chevron-right:before {
  content: "\ea42";
}
.icon-circle-chevron-bottom:before {
  content: "\ea43";
}
.icon-circle-chevron-left:before {
  content: "\ea44";
}
.icon-circle-arrow-left:before {
  content: "\ea45";
}
.icon-circle-arrow-right:before {
  content: "\ea46";
}
.icon-circle-arrow-top:before {
  content: "\ea47";
}
.icon-circle-arrow-bottom:before {
  content: "\ea48";
}
.icon-circle-radio:before {
  content: "\ea54";
}
.icon-circle-radio-inverse:before {
  content: "\ea57";
}
.icon-bullet-point:before {
  content: "\ea55";
}
.icon-circle:before {
  content: "\ea56";
}
.icon-text-align-left:before {
  content: "\ea77";
}
.icon-external-link:before {
  content: "\ea7e";
}
.icon-mail:before {
  content: "\ea83";
}
.icon-at-sign:before {
  content: "\ea84";
}
.icon-clipboard-checked:before {
  content: "\ea85";
}
.icon-all-requests:before {
  content: "\ea86";
}
.icon-my-requests:before {
  content: "\ea87";
}
.icon-time-selected:before {
  content: "\ea88";
}
.icon-calendar-end:before {
  content: "\ea89";
}
.icon-calendar-start:before {
  content: "\ea8a";
}
.icon-time-left:before {
  content: "\ea8b";
}
.icon-users:before {
  content: "\ea8c";
}
.icon-star1:before {
  content: "\ea8d";
}
.icon-wallet1:before {
  content: "\ea8e";
}
.icon-stopwatch:before {
  content: "\ea8f";
}
.icon-more-vertical:before {
  content: "\ea90";
}
.icon-grid:before {
  content: "\ea91";
}
.icon-grid-row-3:before {
  content: "\ea92";
}
.icon-hashtag:before {
  content: "\ea93";
}
.icon-verified1:before {
  content: "\ea94";
}
.icon-verified1-fill:before {
  content: "\eadf";
}
.icon-not-verified:before {
  content: "\eae0";
}
.icon-not-verified-fill:before {
  content: "\eae1";
}
.icon-creditcard-face:before {
  content: "\ea95";
}
.icon-at-sign1:before {
  content: "\ea96";
}
.icon-user-circle:before {
  content: "\ea97";
}
.icon-globe1:before {
  content: "\ea98";
}
.icon-location:before {
  content: "\eada";
}
.icon-heart:before {
  content: "\ea99";
}
.icon-compass:before {
  content: "\ea9a";
}
.icon-arrows-diagonals:before {
  content: "\ea9f";
}
.icon-filter:before {
  content: "\eaa0";
}
.icon-contract:before {
  content: "\eaa1";
}
.icon-file-id:before {
  content: "\eaa2";
}
.icon-file-personal:before {
  content: "\eaa3";
}
.icon-file-visa:before {
  content: "\eaa4";
}
.icon-folder-opened:before {
  content: "\eaa5";
}
.icon-folder:before {
  content: "\eaa6";
}
.icon-chevron-sort:before {
  content: "\eaa7";
}
.icon-flag:before {
  content: "\eaa8";
}
.icon-file:before {
  content: "\eaa9";
}
.icon-file-upload:before {
  content: "\eaaa";
}
.icon-file-checked:before {
  content: "\eaac";
}
.icon-file-plus:before {
  content: "\eaad";
}
.icon-clipboard-checked1:before {
  content: "\eaaf";
}
.icon-calendar-minus:before {
  content: "\eab0";
}
.icon-calendar-plus:before {
  content: "\eab1";
}
.icon-time-history:before {
  content: "\eab2";
}
.icon-grid-col-2:before {
  content: "\eab3";
}
.icon-share:before {
  content: "\eab4";
}
.icon-bar-chart:before {
  content: "\eab5";
}
.icon-calculator:before {
  content: "\ea9b";
}
.icon-infinity:before {
  content: "\eab6";
}
.icon-refresh-cw:before {
  content: "\eab7";
}
.icon-printer:before {
  content: "\eab8";
}
.icon-female:before {
  content: "\eab9";
}
.icon-male:before {
  content: "\eaba";
}
.icon-education:before {
  content: "\ea9c";
}
.icon-gender:before {
  content: "\ea9d";
}
.icon-connected:before {
  content: "\ea9e";
}
.icon-activity:before {
  content: "\eabb";
}
.icon-gosi:before {
  content: "\eabc";
}
.icon-health-insurance:before {
  content: "\eabd";
}
.icon-housing:before {
  content: "\eabe";
}
.icon-visa-fee:before {
  content: "\eabf";
}
.icon-support:before {
  content: "\eac0";
}
.icon-thumbs-up:before {
  content: "\eac1";
}
.icon-creditcard-income:before {
  content: "\eac2";
}
.icon-alarm:before {
  content: "\eac3";
}
.icon-alarm-plus:before {
  content: "\ead7";
}
.icon-alarm-minus:before {
  content: "\ead8";
}
.icon-cloud-download:before {
  content: "\eac4";
}
.icon-shuffle:before {
  content: "\eac5";
}
.icon-player-list-add:before {
  content: "\ead9";
}
.icon-baby:before {
  content: "\eac6";
}
.icon-bike:before {
  content: "\eac7";
}
.icon-dental:before {
  content: "\eac8";
}
.icon-food:before {
  content: "\eac9";
}
.icon-game:before {
  content: "\eaca";
}
.icon-glasses:before {
  content: "\eacb";
}
.icon-gym:before {
  content: "\eacc";
}
.icon-lunch:before {
  content: "\eacd";
}
.icon-rocket:before {
  content: "\eace";
}
.icon-school:before {
  content: "\eacf";
}
.icon-sunbed:before {
  content: "\ead0";
}
.icon-tree:before {
  content: "\ead1";
}
.icon-wifi:before {
  content: "\ead2";
}
.icon-square:before {
  content: "\ead3";
}
.icon-square-checked:before {
  content: "\ead4";
}
.icon-square-indeterminate:before {
  content: "\eadb";
}
.icon-square1:before {
  content: "\eadc";
}
.icon-square-checked-fill:before {
  content: "\ead5";
}
.icon-bookmark:before {
  content: "\ead6";
}
.icon-shield-ok:before {
  content: "\eadd";
}
.icon-key:before {
  content: "\eade";
}
.icon-muqeem:before {
  content: "\eae2";
}
.icon-resignation:before {
  content: "\eae3";
}
.icon-emoticon-happy:before {
  content: "\eae4";
}
.icon-emoticon-sad:before {
  content: "\eae5";
}
.icon-megaphone:before {
  content: "\eae6";
}
.icon-goal:before {
  content: "\eae7";
}
.icon-contract-contractor:before {
  content: "\eae8";
}
.icon-contract-employee:before {
  content: "\eae9";
}
.icon-range:before {
  content: "\eafb";
}
.icon-target:before {
  content: "\eafc";
}
.icon-alarm1:before {
  content: "\eafd";
}
.icon-fingerprint:before {
  content: "\eafe";
}
.icon-saudi .path1:before {
  content: "\eaea";
  color: rgb(25, 157, 0);
}
.icon-saudi .path2:before {
  content: "\eaeb";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-saudi .path3:before {
  content: "\eaec";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-saudi .path4:before {
  content: "\eaed";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-saudi .path5:before {
  content: "\eaee";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-saudi .path6:before {
  content: "\eaef";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-saudi .path7:before {
  content: "\eaf0";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-saudi .path8:before {
  content: "\eaf1";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-saudi .path9:before {
  content: "\eaf2";
  margin-left: -1em;
  color: rgb(27, 164, 0);
}
.icon-saudi .path10:before {
  content: "\eaf3";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-saudi .path11:before {
  content: "\eaf4";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-saudi .path12:before {
  content: "\eaf5";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-saudi .path13:before {
  content: "\eaf6";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-saudi .path14:before {
  content: "\eaf7";
  margin-left: -1em;
  color: rgb(27, 157, 0);
}
.icon-saudi .path15:before {
  content: "\eaf8";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-saudi .path16:before {
  content: "\eaf9";
  margin-left: -1em;
  color: rgb(37, 159, 0);
}
.icon-saudi .path17:before {
  content: "\eafa";
  margin-left: -1em;
  color: rgb(32, 144, 0);
}

@import 'assets/sass/styleguide/variables';

.statusChip {
    text-align: center;
    border-radius: 4px;
    padding: 0.25rem 1rem;
    font-weight: 700;
    line-height: 15.95px;
    font-size: $font-size-11;
    &.done,&.completed{
        color: #62D26F;
        background-color: rgba(98, 210, 111, 0.3);
    }
    &.booked,&.inProgress{
        color: #4EB1F9;
        background-color: rgba(78, 177, 249, 0.3);
    }
    &.viewed,&.toDo,&.system{
        color: #5D728C;
        background-color: rgba(147, 165, 177, 0.3);
    }
}

.system{
    font-weight: 400;
    line-height:24px;
}
@import 'assets/sass/styleguide/variables';

/* FIRST LEVEL - SearchFilterMenu */
.wrapper {
  background-color: $white;
  width: 480px;
  height: 100%;
  min-height: 100vh;
  z-index: 1250;
}

.footer {
  padding: 1.5rem 2rem;
}

/* SECOND LEVEL - FilterMenuGroup */
.menuDiv {
  position: relative;

  h5 {
    padding: 8px;
    font-weight: bold;
    color: var(--text-main);
    font-size: $font-size-14;
    line-height: 1.4;
    margin: 0;
  }

  ul {
    padding: 0;
    margin: 0;
    list-style: none;

    .listItem {
      list-style: none;
      padding: 0.5rem;
      font-size: $font-size-14;

      &:last-child{
        margin-bottom: 0.75rem;
      }
    }
  }
}

/* TABS */
.tab {
  height: 40px;
  border-radius: 4px;
  background-color: $gray-50;
  padding: 8px 8px 8px 16px;
  margin-bottom: 0.5rem;

   span {
    color: var(--secondary);
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 128px;
    display: inline-block;
  }
}


/* ARABIC VERSION */
body[class*="lang-ar"] {
  .wrapper {
    left: 50%;
    right: auto;
  }
}

.modal {
  position: fixed;
  z-index: 999999;
  padding: 3rem 1.5rem 2rem;
  width: 90vw;
  max-width: 1088px;
  height: auto;
  max-height: 90vh;
  top: auto;
  bottom: 5vh;
  left: 50%;
  transform: translateX(-50%);

  .closeButton {
    visibility: visible;
  }
}


body[class*="lang-ar"] {
  .modal {
    .closeButton {
      left: 0.5rem;
      right: auto;
    }
  }
}

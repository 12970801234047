@import "../assets/sass/styleguide/variables";

.selectMenu {
  right: 16px;
  left: initial;
  top: 56px;
  position: absolute;
  box-shadow: $box-shadow-04dp;
  z-index: 10;
  font-size: $font-size-14;
  min-width: 117px;
  .listItem {
    text-transform: capitalize;
    cursor: pointer;
    &:hover,
    &.active {
      background-color: $gray-50;
      i {
        color: $primary;
      }
    }
  }
  i {
    font-size: $font-size-base;
    color: $gray-500;
  }
}

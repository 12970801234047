@import "../../../assets/sass/styleguide/variables";
.wrapper {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1040;
    display: flex;
    justify-content: center;
    align-items: center;
}

.content {
    width: 90vw;
    height: 90vh;
    border-radius: 8px;
}

.btnBox {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    border: 1px solid $gray-300;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $white;
    position: absolute;
    z-index: 100;
}

.btnClose {
    composes: btnBox;
    right: -10px;
    top: -10px;
}

.prevBtn {
    composes: btnBox;
    left: -28px;
    top: 50%;
}

.nextBtn {
    composes: btnBox;
    right: -28px;
    top: 50%;
}

.actionsBox {
    position: absolute;
    right: 50px;
    top: 42px
}

.actionBtn {
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    border: 1px solid $gray-100 !important;
    box-shadow: 0px 2px 3px 2px rgba(40, 75, 100, 0.02);
    &:hover {
        box-shadow: $box-shadow-04dp;
    }
}

.tooltip {
    left: -100% !important;
}
@import "../assets/sass/styleguide/variables";

.wrapper {
  .inputField {
    position: absolute;
    opacity: 0;
    &:hover + .label:before {
      color: $primary;
    }
    &:checked + .label {
      color: $dark;
      &:before {
        content: "\ea54";
        color: $primary;
      }
    }
  }

  .light {
    .inputField {
      &:checked + .label {
        color: $white;
      }
    }
  }

  .label {
    position: relative;
    cursor: pointer;
    padding: 0;
    margin: 0;
    color: $gray-500;
    line-height: 1;
    &:before {
      font-family: "palmhr";
      color: $gray-500;
      content: "\ea56";
      margin-right: 10px;
      display: inline-block;
      vertical-align: text-top;
      font-size: 1.25rem;
      width: 20px;
      height: 20px;
    }
  }
}

@import '../../assets/sass/styleguide/variables';
.deleteBtn {
    position: absolute;
    top: 24px;
    right: 16px;
}

textarea {
    resize: vertical;
}

.errorMessage {
    color: $red;
    font-size: 14px;
    margin-right: 1rem;
}

.overfowX {
    overflow-x: hidden;
}
@import "../../../assets/sass/styleguide/variables";

.wrapper {
  cursor: pointer;
  border-bottom: 1px solid $gray-50;

  &:hover {
    background-color: $light;

    .deleteBtn {
      visibility: visible;
    }
  }

  &:first-of-type {
    border-top: 8px solid $white;
  }

  color: #5D728C;
}

.userList {
  margin-bottom: 130px;
}
.inactiveRow{
  background-color: $gray-50;
}


@import 'assets/sass/styleguide/variables';
.row {
    cursor: pointer;
    overflow-y:auto;
    &:hover {
        background-color: transparent;
    }
    &:first-child {
        td {
            border-top: 4px solid $white;
        }
    }
}

.fullWidthDescription {
    width: 100%;
    display: flex;
    justify-content: start;
  }

.tableData {
    padding: 1rem 0.75rem 1rem 0;
    color: $steal-teal;
    font-size: $font-size-14;
    vertical-align: middle;
    &:last-child {
        padding-right: 1rem;
    }
  }

body[class*="lang-ar"] {
    .tableData {
      padding: 1rem 0 1rem 0.75rem;
    }
  }

@import '../assets/sass/styleguide/variables';

.sendingOverlay {
	position: absolute;
	z-index: 100;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	background-color: rgba(255, 255, 255, 0.9);
}

.content {
	height: 150px;
	position: relative;
	display: flex;
}

.box {
	position: absolute;
	height: 110px;
	width: 110px;
	left: 50%;
	transform: translate(-50%, 0);
}

.smallCheck {
	position: absolute;
	top: 58%;
	left: 41%;
	transform: translate(-50%, -50%) rotate(40deg);
	height: 5%;
	width: 17%;
	border-radius: 5vw;
	background-color: $green;
	animation: smallCheck 500ms linear;
}

.bigCheck {
	position: absolute;
	top: 49%;
	left: 56.5%;
	transform: translate(-50%, -50%) rotate(-50deg);
	height: 5%;
	width: 39%;
	border-radius: 5vw;
	background-color: $green;
	animation: bigCheck 500ms linear;
}

.circle {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	height: 90%;
	width: 90%;
	border-radius: 100vw;
	border: solid 6px $green;
	animation: spin 500ms linear;
}

.text {
	font-size: 1.25rem;
	opacity: 0;
	animation: show 100ms ease-in-out 500ms forwards;
}

@keyframes spin {
	0% {
		transform: translate(-50%, -50%) rotate(180deg);
		border-left: solid 6px rgba(0, 0, 0, 0);
	}
	50% {
		transform: translate(-50%, -50%) rotate(50deg);
	}
	100% {
		transform: translate(-50%, -50%) rotate(50deg);
		border-left: solid 6px $green;
	}
}

@keyframes smallCheck {
	0% {
		width: 0px;
		top: 43%;
		left: 10%;
		transform: translate(-50%, -50%) rotate(40deg);
	}
	50% {
		width: 5px;
		top: 43%;
		left: 20%;
		transform: translate(-50%, -50%) rotate(40deg);
		background-color: $green;
	}
	100% {
		width: 35%;
		background-color: $green;
	}
}

@keyframes bigCheck {
	0% {
		width: 0px;
	}
	80% {
		width: 0px;
	}
	100% {
		top: 54%;
		left: 58%;
		transform: translate(-50%, -50%) rotate(-50deg);
	}
}

@keyframes show {
	to {
		opacity: 1;
	}
}

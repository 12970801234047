@import 'assets/sass/styleguide/variables';

.statusChip {
    text-align: center;
    border-radius: 4px;
    width: 120px;
    padding: 0.25rem 1rem;
    font-weight: 700;
    line-height: 15.95px;
    font-size: $font-size-12;
    &.active{
        color: #62D26F;
        background-color: rgba(98, 210, 111, 0.3);
    }
    &.inactive,&.archived,&.inTrial,&.nonRenewing{
        color: #5D728C;
        background-color: rgba(147, 165, 177, 0.3);
    }
    &.failed,&.suspended,&.cancelled{
        color: #F45147;
        background-color: rgba(244, 81, 71, 0.3);
    }
    &.pending,&.pendingSuspension{
        color: #FFA150;
        background-color: rgba(255, 161, 80, 0.3);
    }
}
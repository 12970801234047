@import "../assets/sass/styleguide/variables";

.wrapper {
  min-height: 100vh;
}

.table thead tr th:nth-child(3) div {
  direction: rtl;
}

.fixedHeader th {
  position: sticky;
	top: 0;
	z-Index: 1
}
@import 'assets/sass/styleguide/variables';

.name {
  font-size: $font-size-28;
  line-height: 1.3;
  font-weight: bold;
  width: 100%;
  max-width: 450px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.logoBox {
  background-color: $white;
  position: relative;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  .imgHolder {
    width: 100%;
    height: 100%;
    overflow: hidden;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  img {
    width: 100%;
    height: auto;
  }
  &:hover .upload {
    opacity: 1;
  }
}

.tableHead {
  border-bottom: 8px solid $white;
  th:not(:first-child) {
     padding: 0.5rem 0;
  }
}

.tableBody {
  .td {
      padding: 0.75rem 1rem;
  }
}

.flagBox {
  background-color: $white;
  position: absolute;
  overflow: visible;
  bottom: 0;
  right: 0;
  border-radius: 24px;
  border: 1px solid $white;
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 5;
}

.fixed {
  th {
    position: sticky;
    white-space: nowrap;
    top: 0;
    padding: 0.75rem 2rem 0.75rem 0;
    z-index: 10;
  }
}

.infoIcon {
  position: absolute;
  top: -4px;
  right: 1rem;
}

body[class*="lang-ar"] {
  .flagBox {
    right: initial;
    left: 0;
  }
}

@media  screen and (max-width: 1299px) {
  .name {
    max-width: 300px;
  }
}

@import '../../assets/sass/styleguide/variables';

.tab {
  background-color: transparent;
  color: var(--text-third) !important;
  text-transform: uppercase;
  font-weight: 700 !important;
  font-size: $font-size-11;
  line-height: 16px;
  position: relative;
  border: none;

  &:active {
    border: none;
    background-color: $gray-100 !important;
  }

  &:hover, &:focus, &.active {
    border: none;
    background-color: $gray-100 !important;
    color: var(--text-main) !important;
    box-shadow: none;
    filter: none;

    &.error, .showError {
      color: rgba($color: var(--danger-rgb), $alpha: 1);
    }
  }

  &.error {
    background-color: rgba($color: var(--danger-rgb), $alpha: 0.1);
    color: rgba($color: var(--danger-rgb), $alpha: 0.5);
    box-shadow: none;
  }
}

.number {
  color: var(--text-fifth);
}

.showError {
  color: rgba($color: var(--danger-rgb), $alpha: 0.5);
}

.tab:not(:last-child) {
  margin-right: 1rem;
}


body[class*="lang-ar"] {
  .tab:not(:last-child) {
    margin-right: initial;
    margin-left: 1rem;
  }
}
@import "../../../../assets/sass/styleguide/variables";
.emptyList {
    width: 230px;
}

.iconHistory {
    width: 88px;
    height: 88px;
    background-color: $gray-50;
    border-radius: 50%;
}

.icon {
    font-size: 2.5rem;
    color: $gray-300;
}

.btnDevices {
    color: $primary;
    border-color: transparent;
    background-color: transparent;
    font-size: 0.875rem;
    line-height: 1.6;
    display: inline-block;
    font-weight: 400;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    &:hover {
        color: #2aa795;
        border-color: transparent;
    }
    &:focus {
        box-shadow: none;
        border-color: transparent;
        outline: none;
    }
}
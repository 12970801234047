@import "assets/sass/styleguide/variables";
  .selected {
    border-color: $primary !important;
  }
  
  .content {
    cursor: pointer;
    transition: box-shadow 0.1s ease-in;
    border: 1px solid $gray-100;
    border-radius: 8px;
    &:hover {
      border: none;
      box-shadow: $box-shadow-06dp;
    }
}

.info {
    font-size: $font-size-14;
    color: var(--text-second);
}
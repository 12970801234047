@import 'assets/sass/styleguide/variables';
.row {
    height: 60px;
    max-height: 60px;
    cursor: pointer;
    &:hover {
        background-color: transparent;
    }
    &:first-child {
        td {
            border-top: 4px solid $white;
        }
    }
}

.moreBtn{
    &:hover {
        border-radius: 5px;
        background-color:$light;
    }
}

.icons {
    width:5%;
}

.assignees{
    width: 200px;
    max-width: 200px;
}

.fixedWidth{
    width: 160px;
    max-width: 160px;
}
.hoverbg{
    &:hover {
        background-color:$light;
    }
}
.tableData {
    padding: 1rem 0.75rem 1rem 0;
    color: $steal-teal;
    font-size: $font-size-14;
    vertical-align: middle;
    &:last-child {
        padding-right: 1rem;
    }
  }
  body[class*="lang-ar"] {
    .tableData {
      padding: 1rem 0 1rem 0.75rem;
      &:last-child {
          padding-right: initial;
          padding-left: 1rem;
      }
    }
  }

@import 'assets/sass/styleguide/variables';

.wrapper {
  border: 1px solid $gray-100;
  border-radius: 8px;
  width: 235px;
  max-width: 235px;
  cursor: pointer;
  &:hover {
    border: none;
    box-shadow: $box-shadow-04dp;
  }
  a {
    color: var(--text-main);
  }
}

.logoWrapper {
  position: relative;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.count {
  font-size: $font-size-13;
}


.flagBox {
  position: absolute;
  overflow: visible;
  bottom: 0;
  right: 0;
  border-radius: 24px;
  border: 1px solid $white;
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 100%;
    height: auto;
  }
}

.name{
  font-size: large;
  min-height: 28px;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

body[class*="lang-ar"] {
  .root {
    &:after {
      left: auto;
      right: 2rem;
    }
  }
}

@import "../../assets/sass/styleguide/variables";

.wrapper {
  border-radius: 8px;
  border: 1px solid $gray-100;
  h6{
    font-size: 18px;
  }
}
.date {
  margin-bottom: 0.5rem;
  color: $gray-500;
  font-size: $font-size-14;
  font-weight: bold;
  line-height: 20px;
}
.text {
  font-size: $font-size-14;
  line-height: 20px;
  color: $gray-500;
  text-align: center;
}
.number {
  font-size: $font-size-24;
  line-height: 28px;
  font-weight: bold;
  text-align: center;
}
.percent {
  color: $gray-500;
  font-size: $font-size-14;
  line-height: 20px;
  text-align: center;
}
.icon {
  font-size: 2.5rem;
}
.vs {
  border-radius: 50%;
  border: 1px solid $gray-100;
  width: 32px;
  height: 32px;
  line-height: 32px;
  font-size: $font-size-12;
  color: $gray-500;
  text-align: center;
  position: absolute;
  left: -16px;
  top: 42px;
  background-color: $white;
}
body[class*="lang-ar"] {
  .vs {
    left: initial;
    right: -16px;
  }
}

.gradient {
  background-color: $gray-50;
  background-image: linear-gradient(
    90deg,
    $gray-100 0px,
    rgba($gray-50, 0.9) 300px,
    $gray-100 500px
  );
  background-size: 2000px;
  animation: shine-lines 1.3s infinite linear;
  opacity: 0.8;
}
@keyframes shine-lines {
  0% {
    background-position: -750px - 370px;
  }
  40%,
  100% {
    background-position: 600px - 370px;
  }
}
@import "../assets/sass/styleguide/variables";

.tab {
  height: 24px;
  background-color: transparent;
  color: $gray-500;
  text-transform: uppercase;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: none;
  border: none;
  &:hover,
  &:focus,
  &.active {
    background-color: $gray-100;
    color: $dark;
    box-shadow: none;
    border: none;
    outline: none;
  }
  span {
    font-size: $font-size-11;
    line-height: 16px;
    text-transform: uppercase;
  }
  .total {
    color: $gray-500;
    margin-left: 0.25rem;
  }
}

@mixin bg-states($selector: false, $theme: #043344) {
  &:hover {
    @if $selector {
      &:#{$selector} {
        background-color: rgba($color: $theme, $alpha: 0.04);
      }
    } @else {
      background-color:rgba($color: $theme, $alpha: 0.04);
    }
  }
  &:focus {
    @if $selector {
      &:#{$selector} {
        background-color: rgba($color: $theme, $alpha: 0.06);
      }
    } @else {
      background-color: rgba($color: $theme, $alpha: 0.06);
    }
  }
  &:active {
    @if $selector {
      &:#{$selector} {
        background-color: rgba($color: $theme, $alpha: 0.1);
      }
    } @else {
      background-color: rgba($color: $theme, $alpha: 0.1);
    }
  }
}

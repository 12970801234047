@import "../assets/sass/styleguide/variables";

.header {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  padding: 1rem 0.75rem;
  margin-bottom: 0.5rem;
  overflow: hidden;
  min-height: 65px;

  .logo {
    width: 2rem;
    cursor: pointer;
  }
}
